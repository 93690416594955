<template>
    <div>
        <b-row>
            <b-col>
                <b-table
                    ref="atxTable"
                    stacked="md"
                    :per-page="tablePagination.perPage"
                    :current-page="tablePagination.currentPage"
                    v-bind="$attrs"
                    :sort-by="sortBy"
                    v-on="$listeners"
                    @row-clicked="onRowClicked"
                >
                    <template #table-busy>
                        <atx-table-loader
                            :fields="atxTableReference.computedFields"
                            :row-number="atxTableReference.computedItems.length"
                        />
                    </template>
                    <template
                        v-for="(_, bTableSlot) of $scopedSlots"
                        #[bTableSlot]="scope"
                    >
                        <slot
                            :name="bTableSlot"
                            v-bind="scope"
                        ></slot>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="pagination">
            <b-col>
                <b-form-group
                    label="Per page"
                    label-for="per-page-select"
                    label-cols="auto"
                    label-align-lg="right"
                    label-size="sm"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="tablePagination.perPage"
                        :options="visiblePageOptions"
                        size="sm"
                        class="size-60"
                        @input="paginationInputHandler"
                    ></b-form-select>
                    &nbsp;
                    <small v-if="isMounted">
                        Showing {{ paginatedFromItems }} to
                        {{ paginatedToItems }}
                        of
                        {{ atxTableReference.filteredItems.length }} entries
                    </small>
                </b-form-group>
            </b-col>
            <b-col>
                <b-pagination
                    v-model="tablePagination.currentPage"
                    pills
                    :total-rows="tablePagination.totalRows"
                    :per-page="
                        tablePagination.perPage === 0
                            ? tablePagination.totalRows
                            : tablePagination.perPage
                    "
                    align="right"
                    size="sm"
                    class="mb-3 atx-pagination"
                    @input="paginationInputHandler"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-row v-if="saveButton">
            <b-col>
                <atx-button
                    id="btn-save-mappings"
                    :disabled="saveButtonDisabled"
                    variant="primary"
                    @click="saveButtonClickHandler"
                >
                    {{ saveButtonText }}
                </atx-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-alert
                    v-for="msg in errorMessages"
                    :key="msg"
                    variant="atx-red-1"
                    class="mt-3"
                    show
                >
                    >{{ msg }}
                </b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
import { BTable } from "bootstrap-vue";
import Vue from "vue";
import { AtxTablePageOption, AtxTablePagination } from "./models";

export default Vue.extend({
    props: {
        saveButton: {
            type: Boolean,
            default: false,
        },
        saveButtonDisabled: {
            type: Boolean,
            default: false,
        },
        saveButtonClickHandler: {
            type: Function,
            required: false,
        },
        saveButtonText: {
            type: String,
            default: "Save button",
        },
        sortBy: {
            type: String,
            default: "",
        },
        pagination: {
            type: Boolean,
            default: false,
        },
        paginationInputHandler: {
            type: Function,
            required: true,
        },
        paginationParams: {
            type: Object as () => AtxTablePagination,
            default: () => ({
                perPage: 10,
                currentPage: 1,
                pageOptions: [
                    { value: 10, text: "10", visible: true },
                    { value: 25, text: "25", visible: true },
                    { value: 50, text: "50", visible: true },
                    { value: 100, text: "100", visible: true },
                ],
                totalRows: 0,
            }),
        },
        errorMessages: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isMounted: false,
            tablePagination: this.paginationParams,
            selectedLastRow: -1,
        };
    },
    computed: {
        atxTableReference(): BTable {
            if (!this.isMounted)
                return {
                    computedItems: [],
                } as any;
            return this.$refs.atxTable as BTable;
        },
        visiblePageOptions(): AtxTablePageOption[] {
            return this.tablePagination.pageOptions.filter((option) => option.visible !== false);
        },
        paginatedFromItems(): number {
            let paginatedFromItems =
                this.tablePagination.perPage * (this.tablePagination.currentPage - 1) + 1;

            if (this.atxTableReference.filteredItems.length === 0) {
                paginatedFromItems = 0;
            }
            return paginatedFromItems;
        },
        paginatedToItems(): number {
            return this.tablePagination.perPage * this.tablePagination.currentPage >
                this.atxTableReference.filteredItems.length || this.tablePagination.perPage === 0
                ? this.atxTableReference.filteredItems.length
                : this.tablePagination.perPage * this.tablePagination.currentPage;
        },
    },
    mounted() {
        this.isMounted = true;

        const vm = this;
        document.onmousedown = function (e) {
            var shiftKeyPressed = e.shiftKey;

            if (shiftKeyPressed) {
                vm.toggleClassOnRow();
                document.onmouseup = function () {
                    vm.toggleClassOnRow(false);
                };
            }
        };
    },
    methods: {
        onRowClicked(item: any, index: number, event: MouseEvent) {
            const selectedLastRow = this.selectedLastRow;
            let selectedRows: any[] = [];
            let selected = !selectedRows[index];
            if (selectedLastRow > -1 && event.shiftKey) {
                for (
                    let idx = Math.min(selectedLastRow, index);
                    idx < Math.max(selectedLastRow, index);
                    idx++
                ) {
                    selectedRows[idx] = true;
                }
                selected = true;
                selectedRows.forEach((item: any, index: number) => {
                    if (item) {
                        this.atxTableReference.selectRow(index);
                    } else {
                        this.atxTableReference.unselectRow(index);
                    }
                });
            } else {
                if (!(event.ctrlKey || event.metaKey)) {
                    // Clear range selection if any
                    selectedRows = [];
                    selected = true;
                }
                if (selected) this.selectedLastRow = index;
            }
            selectedRows[index] = selected;
        },
        toggleClassOnRow(add = true) {
            const listOfTableRows = this.atxTableReference.$el.children["1"].children;
            for (const item of listOfTableRows) {
                if (add) {
                    item.classList.add("disable-user-select");
                } else {
                    item.classList.remove("disable-user-select");
                }
            }
        },
    },
});
</script>
<style lang="scss">
.disable-user-select {
    -moz-user-select: "-moz-none";
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
