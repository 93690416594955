<template>
    <b-overlay
        id="atx-app"
        :show="loading"
        class="d-flex flex-column vh-100"
        z-index="9999"
    >
        <router-view></router-view>
    </b-overlay>
</template>

<script lang="ts">
import Vue from "vue";
import { mapStores } from "pinia";
import { useLoaderStore } from "@/stores/index";

export default Vue.extend({
    computed: {
        ...mapStores(useLoaderStore),
        loading(): boolean {
            return this.loaderStore.loading;
        },
        isProductionEnv() {
            return import.meta.env.VITE_APP_NODE_ENV === "production";
        },
    },
    errorCaptured(err: Error, vm, info) {
        if (!this.isProductionEnv) return;
        // handle error
        // `info` is a Vue-specific error info, e.g. which lifecycle hook
        // the error was found in. Only available in 2.2.0+
        vm.$root.$gtag.query("event", "js_error", {
            js_error: err,
            js_stack: err.stack,
            js_info: info,
        });
    },
});
</script>
