
<b-container
    fluid="xl"
    class="my-4"
>
    <stepper-card-skeleton />
    <atx-separator :margin="4" />
    <div class="statistics-and-settlements-wrapper position-relative">
        <statistics-card-skeleton />
        <atx-separator :margin="4" />
        <settlements-card-skeleton />
    </div>
</b-container>
