
<b-badge
    :class="{
        'atx-billing-badge': true,
        'bg-transparent': true,
        'text-atx-grey-4': !disabled,
        'text-atx-grey-2': disabled,
        border: true,
        'border-atx-grey-2': true,
    }"
>
    <slot></slot>
</b-badge>
