
<b-nav class="atx-secondary-nav mb-4">
    <b-nav-item
        v-for="(navItem, index) in navItems"
        :key="index"
        class="border-bottom mb-4 border-5 pr-5"
        :class="{
            'border-atx-grey-2': !isActive(navItem) && !navItem.active,
            'border-atx-blue-2': isActive(navItem) || navItem.active,
        }"
        :to="navItem.to ? navItem.to : null"
        :href="navItem.href ? navItem.href : null"
        @click="() => clickHandler(navItem)"
    >
        <span
            :class="{
                'text-atx-blue-2': isActive(navItem) || navItem.active,
            }"
        >
            {{ navItem.label }}
        </span>
    </b-nav-item>
</b-nav>
