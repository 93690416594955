<template>
    <b-card
        :class="componentName"
        :data-test-id="componentName"
        :aria-expanded="visible ? 'true' : 'false'"
    >
        <b-row
            align-v="center"
            role="button"
            @click="toggleCollapse"
        >
            <b-col cols="10">
                <h5 class="mb-0">{{ title }}</h5>
            </b-col>
            <b-col
                cols="2"
                class="text-right"
            >
                <atx-icon :icon="icon" />
            </b-col>
        </b-row>
        <template v-if="persistent">
            <b-collapse
                v-model="visible"
                class="mt-4"
            >
                <div
                    v-if="busy"
                    class="m-5 p-5 text-center"
                >
                    <b-spinner />
                </div>
                <slot v-else></slot>
            </b-collapse>
        </template>
        <template v-else>
            <div v-if="visible">
                <div
                    v-if="busy"
                    class="m-5 p-5 text-center"
                >
                    <b-spinner />
                </div>
                <slot v-else></slot>
            </div>
        </template>
    </b-card>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        title: {
            type: String,
            required: true,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        /**
         * If true this will persist the dom elements after showing / hiding
         */
        persistent: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            componentName: "atx-expandable-card",
            visible: this.expanded,
        };
    },
    computed: {
        icon(): string {
            return this.visible ? "chevron-up" : "chevron-down";
        },
    },
    watch: {
        // Needed to keep expanded and visible in sync
        expanded(newVal: boolean) {
            this.visible = newVal;
        },
    },
    methods: {
        toggleCollapse() {
            this.visible = !this.visible;
            this.$emit("toggle", this.visible);
        },
    },
});
</script>
