
<b-modal
    v-if="rulesMet"
    v-bind="$attrs"
    :id="id"
    visible
    body-class="p-5"
    :size="size"
    :centered="centered"
    :save-cookie="saveCookie"
    :reset-cookie="resetCookie"
    hide-footer
    hide-header
    lazy
    static
    no-close-on-backdrop
    no-close-on-esc
    v-on="$listeners"
>
    <div class="atx-modal-content">
        <template v-if="icon !== '' || image !== '' || alertState !== ''">
            <template v-if="showBetaBadge && image">
                <div class="mb-2 text-center">
                    <atx-badge
                        :preset="AtxBadgePresets.BETA"
                        margin-class="m-0"
                    />
                </div>
                <atx-icon
                    icon="star-fill"
                    class="text-atx-orange-2 position-absolute"
                    rotate="-15"
                    scale="1.45"
                    style="top: 26%; left: 19%"
                />
                <atx-icon
                    icon="star-fill"
                    class="text-atx-orange-2 position-absolute"
                    rotate="15"
                    scale="2.25"
                    style="top: 8%; right: 19%"
                />
                <atx-icon
                    icon="star-fill"
                    class="text-atx-orange-2 position-absolute"
                    rotate="-15"
                    scale="1.25"
                    style="top: 20%; right: 20%"
                />
            </template>

            <div class="image-wrap d-flex justify-content-center">
                <!-- Use either icon or image, icon will take precedence over image -->
                <atx-icon
                    v-if="icon !== '' || alertState !== ''"
                    :icon="alertState !== '' ? alertStateIcon : icon"
                    style="width: 38px; height: 38px"
                    :variant="alertState !== '' ? alertStateVariant : iconVariant"
                />
                <img
                    v-else-if="image !== ''"
                    :src="image"
                    alt="Announcement image"
                    width="284"
                    height="199"
                    class="img-fluid"
                />
            </div>
        </template>
        <div class="content-wrap mt-4 mb-2 text-center">
            <template v-if="$scopedSlots.default">
                <slot></slot>
            </template>
            <template v-else>
                <h3 class="dialog-title mb-2 font-weight-normal">{{ title }}</h3>
                <p class="dialog-content">
                    {{ content }}
                </p>
            </template>
            <p
                v-if="supportMessage"
                class="mt-3"
            >
                For more assistance please contact
                <a href="mailto:support@a2xaccounting.com">support</a>
                .
            </p>
        </div>
    </div>
    <div
        v-if="!stacked"
        class="content-buttons d-flex justify-content-center"
    >
        <atx-button
            v-if="!hideOkButton"
            :variant="buttonVariantOkClass"
            @click="ok"
        >
            {{ buttonOkTitle }}
        </atx-button>
        <atx-button
            v-if="showCancelButton"
            class="ml-2"
            :variant="buttonVariantCancelClass"
            @click="cancel"
        >
            {{ buttonCancelTitle }}
        </atx-button>
    </div>
    <div
        v-else
        class="content-buttons text-center"
    >
        <div>
            <atx-button
                v-if="!hideOkButton"
                :variant="buttonVariantOkClass"
                @click="ok"
            >
                {{ buttonOkTitle }}
            </atx-button>
        </div>
        <div>
            <atx-button-link
                v-if="showCancelButton"
                class="mt-3"
                @click="cancel"
            >
                {{ buttonCancelTitle }}
            </atx-button-link>
        </div>
    </div>
</b-modal>
