import { IMenu, IMenuItem } from "@/components/models/IMenu";
import { getMappingTerminology, getSettlementTerminology } from "@/helpers";
import { AccountingSystem, FEATURE_FLAGS, SalesSource } from "@/models";
import {
    ACCOUNT_SETTINGS_PAGE_ROUTES,
    ADMIN_PAGE_ROUTES,
    AMAZON_PAGE_ROUTES,
    COSTS_PAGE_ROUTES,
    INVENTORY_PAGE_ROUTES,
    MAPPING_PAGE_ROUTES,
    REPORTS_PAGE_ROUTES,
    SETTLEMENT_PAGE_ROUTES,
    SHOPIFY_PAGE_ROUTES,
    SUPPORT_LINKS,
    USER_SETTINGS_PAGE_ROUTES,
    WEBSITE_LINKS,
} from "@/router/routeConstants";

const getSettlementItemMenu = (salesSource: SalesSource): IMenuItem[] => {
    const settlementsTerminology = getSettlementTerminology(salesSource, false, true);
    if (salesSource === SalesSource.SHOPIFY) {
        return [
            {
                name: settlementsTerminology,
                path: SETTLEMENT_PAGE_ROUTES.APP_SETTLEMENTS.fullPath,
            },
            {
                name: "Postings",
                path: SETTLEMENT_PAGE_ROUTES.APP_SETTLEMENTS.fullPath,
            },
        ];
    } else
        return [
            {
                name: settlementsTerminology,
                path: SETTLEMENT_PAGE_ROUTES.APP_SETTLEMENTS.fullPath,
            },
        ];
};

export const getMenus = (
    accountingSystem: AccountingSystem,
    salesSource: SalesSource
): Record<string, IMenu> => {
    const mappingTerminology = getMappingTerminology(salesSource);

    // Common settings menu items
    const commonSettingsMenu = [
        {
            name: "Account",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.ACCOUNT_SETTINGS.fullPath,
            icon: "gear",
        },
        {
            name: "Automation",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.AUTOMATION.fullPath}`,
            icon: "arrow-repeat",
        },
        {
            name: "Product Types",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.PRODUCT_TYPES.fullPath,
            icon: "boxes",
        },
        {
            name: "Sales Grouping and Tracking",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.INVOICES.fullPath,
            icon: "file-earmark-text",
        },
        {
            name: "NetSuite Configuration",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.NETSUITE_CONFIGURATION.fullPath}`,
            icon: "netsuite",
        },
        {
            name: "B2B Invoice",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.SHOPIFY_B2B.fullPath}`,
            icon: "cart",
        },
        {
            name: "History",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.HISTORY.fullPath,
            icon: "clock-history",
        },
        {
            name: "Connections",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.CONNECTIONS.fullPath,
            icon: "plug",
        },
        {
            name: "Cost of Goods Sold",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.COGS.fullPath,
            icon: "currency-dollar",
        },
        {
            name: "Inventory",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.INVENTORY.fullPath,
            icon: "box-seam",
        },
        {
            name: "Users",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.USERS.fullPath,
            icon: "people",
        },
        {
            name: "Billing",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.BILLING.fullPath,
            icon: "credit-card-2-back",
        },
        {
            name: "Registered Country",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.REGISTERED_COUNTRY.fullPath,
            icon: "globe-americas",
        },
        {
            name: "Amazon Pay",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.AMAZON_PAY.fullPath,
            icon: "amazon-pay",
        },
        {
            name: "Beta Features",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.BETA_FEATURES.fullPath}`,
            icon: "rocket-takeoff",
            iconClasses: "text-atx-blue-3",
        },
        {
            name: "Backend App",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.BACKEND.fullPath}`,
            icon: "cpu",
        },
    ];

    const commonSettingsPaypalMenu = [
        {
            name: "Account",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.ACCOUNT_SETTINGS.fullPath,
            icon: "gear",
        },
        {
            name: "Automation",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.AUTOMATION.fullPath}`,
            icon: "arrow-repeat",
        },
        {
            name: "Classes/Tracking",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.INVOICES.fullPath,
            icon: "file-earmark-text",
        },
        {
            name: "Statements, Accounts and Taxes",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.STATEMENTS_ACCOUNTS_AND_TAXES.fullPath}`,
            icon: "file-earmark-text",
        },
        {
            name: "NetSuite Configuration",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.NETSUITE_CONFIGURATION.fullPath}`,
            icon: "netsuite",
        },
        {
            name: "History",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.HISTORY.fullPath,
            icon: "clock-history",
        },
        {
            name: "PayPal",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.PAYPAL.fullPath}`,
            icon: "paypal",
        },
        {
            name: "Connections",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.CONNECTIONS.fullPath,
            icon: "plug",
        },
        {
            name: "Users",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.USERS.fullPath,
            icon: "people",
        },
        {
            name: "Billing",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.BILLING.fullPath,
            icon: "credit-card-2-back",
        },
        {
            name: "Beta Features",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.BETA_FEATURES.fullPath}`,
            icon: "rocket-takeoff",
            iconClasses: "text-atx-blue-3",
        },
        {
            name: "Backend App",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.BACKEND.fullPath}`,
            icon: "cpu",
        },
    ];

    const commonMainMenuStartingItems = [
        {
            name: "Home",
            path: ADMIN_PAGE_ROUTES.ADMIN.fullPath,
        },
        ...getSettlementItemMenu(salesSource),
        {
            name: mappingTerminology,
            path: MAPPING_PAGE_ROUTES.MAPPING.fullPath,
        },
    ];

    const commonCostsMainMenuItem = {
        name: "Costs",
        path: COSTS_PAGE_ROUTES.COSTS.fullPath,
    };

    const commonReportsMainMenuItem = {
        name: "Reports",
        path: "#",
        subMenu: [
            {
                name: "Export Data",
                path: REPORTS_PAGE_ROUTES.APP_EXPORT_DATA.fullPath,
                parent: "Reports",
            },
        ],
    };

    const shopifyReportsItems = [
        {
            name: "Finance Report",
            path: SHOPIFY_PAGE_ROUTES.FINANCE_REPORT.fullPath,
            parent: "Reports",
        },
        {
            name: "Gift Card Report",
            path: SHOPIFY_PAGE_ROUTES.GIFT_CARDS_REPORT.fullPath,
            parent: "Reports",
        },
        {
            name: "Reconciliation Report",
            path: `/app${SHOPIFY_PAGE_ROUTES.RECONCILIATION_REPORTS.fullPath}`,
            parent: "Reports",
        },
    ];

    const b2bInvoiceReport = {
        name: "B2B Invoice Report",
        path: `/app${SHOPIFY_PAGE_ROUTES.SINGLE_ORDER_INVOICE_REPORT.fullPath}`,
        parent: "Reports",
    };
    const shopifyReportsMainMenuItem = {
        ...commonReportsMainMenuItem,
        subMenu: [b2bInvoiceReport, ...commonReportsMainMenuItem.subMenu, ...shopifyReportsItems],
    };

    const amazonReportsItems = [
        {
            name: "Summary Report",
            path: `/app${AMAZON_PAGE_ROUTES.DATE_RANGE_SUMMARY_REPORT.fullPath}`,
        },
    ];

    const amazonReportsMainMenuItem = {
        ...commonReportsMainMenuItem,
        subMenu: [...commonReportsMainMenuItem.subMenu, ...amazonReportsItems],
    };

    const commonMainMenu = [
        {
            name: "Need Help",
            path: SUPPORT_LINKS.SUPPORT.path,
            classes: "d-md-none",
            icon: "bell-fill",
            topDivider: true,
        },
        {
            name: "Notifications",
            path: USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.fullPath,
            classes: "d-md-none",
            icon: "question-circle-fill",
        },
        {
            name: "NEW",
            path: WEBSITE_LINKS.WHATS_NEW.path,
            classes: "d-md-none",
            icon: "bi bi-gift",
            type: "pill",
        },
    ];

    return {
        amazon: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                {
                    name: "Inventory",
                    path: "#",
                    subMenu: [
                        {
                            name: "Monthly Summary",
                            path: INVENTORY_PAGE_ROUTES.INVENTORY.fullPath,
                        },
                        {
                            name: "Locations",
                            path: INVENTORY_PAGE_ROUTES.INVENTORY_LOCATIONS.fullPath,
                        },
                    ],
                },
                commonCostsMainMenuItem,
                amazonReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
        shopify: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                commonCostsMainMenuItem,
                shopifyReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
        bigcommerce: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                commonCostsMainMenuItem,
                commonReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
        etsy: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                commonCostsMainMenuItem,
                commonReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
        ebay: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                commonCostsMainMenuItem,
                commonReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
        walmart: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                commonCostsMainMenuItem,
                commonReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
        paypal: {
            mainMenu: [
                ...commonMainMenuStartingItems,
                commonReportsMainMenuItem,
                ...commonMainMenu,
            ],
            settingsMenu: commonSettingsPaypalMenu,
        } as IMenu,
        afterpay: {
            mainMenu: [...commonMainMenuStartingItems, ...commonMainMenu],
            settingsMenu: commonSettingsMenu,
        } as IMenu,
    };
};

export const featureFlagMenuItems = {} as Record<FEATURE_FLAGS, string>;

export const getMenu = (
    accountingSystem: AccountingSystem,
    salesSource: SalesSource,
    featureFlags: FEATURE_FLAGS[] | null = [],
    prodEnvironment = false
): IMenu => {
    const menus = getMenus(accountingSystem, salesSource);
    const salesChannelKey = salesSource.toLowerCase();
    const salesChannelMenu = menus[salesChannelKey];
    const accountingSystemKey = accountingSystem.toLowerCase();

    // filter menu.settingsMenu based on salesChannel, remove an inventory menu for everything except amazon
    salesChannelMenu.settingsMenu = salesChannelMenu.settingsMenu.filter((item) => {
        if (item.name === "Inventory" || item.name === "Amazon Pay") {
            return salesChannelKey === "amazon";
        }
        return true;
    });

    // Remove navigation item if featureFlag is not matching with "featureFlagMenuItems"
    if (featureFlags && Array.isArray(featureFlags)) {
        for (const [featureFlag, menuItemName] of Object.entries(featureFlagMenuItems) as [
            FEATURE_FLAGS,
            string,
        ][]) {
            if (!featureFlags.includes(featureFlag)) {
                salesChannelMenu.settingsMenu = salesChannelMenu.settingsMenu.filter(
                    (item) => item.name !== menuItemName
                );
            }
        }
    }

    // if accounting system is not netsuite remove "Netsuite Configuration" from the list
    if (accountingSystemKey !== "netsuite") {
        salesChannelMenu.settingsMenu = salesChannelMenu.settingsMenu.filter((item) => {
            return item.name !== "NetSuite Configuration";
        });
    }
    if (
        salesChannelKey === "shopify" &&
        featureFlags?.includes(FEATURE_FLAGS.SHOPIFY_SINGLE_ORDER_ENABLED)
    ) {
        menus.shopify.mainMenu = menus.shopify.mainMenu.filter((item) => {
            return item.name !== getSettlementTerminology(SalesSource.SHOPIFY, false, true);
        });
    }

    // if salesChannel is not shopify or FF is not active remove "B2B Invoice" from the list
    if (
        salesChannelKey !== "shopify" ||
        !featureFlags?.includes(FEATURE_FLAGS.SHOPIFY_SINGLE_ORDER_ENABLED)
    ) {
        salesChannelMenu.settingsMenu = salesChannelMenu.settingsMenu.filter((item) => {
            return item.name !== "B2B Invoice";
        });
        menus.shopify.mainMenu = menus.shopify.mainMenu.filter((item) => {
            return item.name !== "Postings";
        });
        salesChannelMenu.mainMenu = salesChannelMenu.mainMenu.filter((item) => {
            if (item.subMenu) {
                item.subMenu = item.subMenu.filter(
                    (subItem) => subItem.name !== "B2B Invoice Report"
                );
            }
            return true;
        });
    }

    if (prodEnvironment) {
        salesChannelMenu.settingsMenu = salesChannelMenu.settingsMenu.filter((item) => {
            return item.name !== "Backend App";
        });
    }

    return salesChannelMenu;
};
