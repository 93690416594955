<template>
    <b-button-group
        class="position-static"
        :data-test-id="testId"
    >
        <b-dropdown
            ref="atx-dropdown-range-datepicker"
            variant="light"
            class="atx-dropdown mx-2"
            toggle-class="border-radius-4 px-2 py-1"
            menu-class="border-radius-12"
            :disabled="dropdownDisabled"
            boundary="window"
            no-caret
            @show="openFilterSelect"
            @hide="closeFilterSelect"
        >
            <template #button-content>
                <span class="dropdown-toggle-button-text">
                    {{ formatRangeSelected }}
                </span>
                <atx-filter-counter-badge
                    v-if="badgeCounter > 0"
                    :counter="badgeCounter"
                />
                <atx-icon :icon="!filterSelectShouldOpen ? 'chevron-down' : 'chevron-up'" />
            </template>

            <atx-date-range-picker
                :calendar-class-main="calendarClassMain"
                v-bind="$attrs"
                v-on="$listeners"
                @range-selected="rangeSelectedHandler"
                :initial-date-range="initialRange"
            ></atx-date-range-picker>
        </b-dropdown>
    </b-button-group>
</template>

<script lang="ts">
import { formatDate, formatShortDate } from "@/helpers/dateUtils";
import { BvEvent } from "bootstrap-vue";
import Vue from "vue";

export default Vue.extend({
    props: {
        /**
         * Must be unique calendar identifier for the page.
         **/
        calendarClassMain: {
            type: String,
            required: true,
            default: "",
        },
        dropdownButtonText: {
            type: String,
            required: false,
            default: "Button text",
        },
        dropdownDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholderText: {
            type: String,
            required: false,
            default: "",
        },
        badgeCounter: {
            type: Number,
            required: false,
            default: 0,
        },
        testId: {
            type: String,
            required: false,
            default: "",
        },
        initialRange: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            filterSelectShouldOpen: false,
            rangeSelected: null as null | Record<string, string>,
        };
    },
    computed: {
        formatRangeSelected(): string {
            const localRangeSelected = this.rangeSelected;

            if (localRangeSelected === null && this.placeholderText) {
                return `${this.dropdownButtonText}: ${this.placeholderText}`;
            }

            if (localRangeSelected === null) return this.dropdownButtonText;
            if (localRangeSelected.from === null || localRangeSelected.to === null)
                return this.dropdownButtonText;

            const startDateObj = new Date(localRangeSelected.from);
            const endDateObj = new Date(localRangeSelected.to);

            const formattedStartDate = formatDate(
                startDateObj,
                "short",
                "numeric",
                startDateObj.getFullYear() != endDateObj.getFullYear() ? "numeric" : undefined
            );
            // startDate format to Month date
            const formattedEndDate = formatShortDate(endDateObj);

            return `${this.dropdownButtonText}: ${formattedStartDate} - ${formattedEndDate}`;
        },
    },
    methods: {
        closeFilterSelect(bvEvent: BvEvent): BvEvent {
            this.filterSelectShouldOpen = false;
            return bvEvent;
        },
        openFilterSelect(): void {
            this.$emit("dropdown-shown");
            this.filterSelectShouldOpen = true;
        },
        rangeSelectedHandler(value: Record<string, any>): void {
            if (value.from === null || value.to === null) {
                this.rangeSelected = null;
            }
            this.rangeSelected = value;
        },
    },
    created() {
        if (this.initialRange && this.initialRange.from && this.initialRange.to) {
            this.rangeSelected = {
                from: this.initialRange.from,
                to: this.initialRange.to,
            };
        }
    },
});
</script>

<style scoped>
.icon-calendar {
    padding: 2px;
    margin-right: 7px;
    margin-bottom: 1px;
}
</style>
