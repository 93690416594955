<template>
    <b-input-group
        v-if="datepickerIcon"
        class="atx-date-picker-wrapper w-100"
    >
        <template #prepend>
            <b-input-group-text class="bg-white">
                <atx-icon icon="calendar4" />
            </b-input-group-text>
        </template>
        <datepicker
            v-bind="$attrs"
            ref="atx-date-picker"
            class="atx-date-picker clear-button-regular"
            :input-class="{
                'border-bottom-left-radius-0 border-top-left-radius-0 bg-white': true,
                ...inputClass,
            }"
            :wrapper-class="{
                ...wrapperClass,
            }"
            :calendar-class="{
                'atx-date-picker-calendar p-2 border-radius-12': true,
                ...calendarClass,
            }"
            :disabled-dates="disabledDates"
            :day-cell-content="dayCellContent"
            :format="format"
            :minimum-view="minimumView"
            :maximum-view="maximumView"
            :inline="inline"
            :clear-button="clearButton && !inline"
            bootstrap-styling
            :open-date="selectedFromDate"
            v-on="$listeners"
            @changedMonth="attachEventListeners"
        ></datepicker>
        <b-tooltip
            v-if="dayTooltip && dayTooltipMessage"
            show
            :target="dayTooltip.target"
            triggers="hover"
            variant="danger"
            placement="top"
        >
            <div v-html="dayTooltipMessage"></div>
        </b-tooltip>
    </b-input-group>
    <div
        v-else
        class="atx-date-picker-wrapper"
    >
        <datepicker
            v-bind="$attrs"
            ref="atx-date-picker"
            :inline="inline"
            class="atx-date-picker"
            :input-class="{
                ...inputClass,
            }"
            :wrapper-class="{
                ...wrapperClass,
            }"
            :calendar-class="calendarClassMerged"
            :disabled-dates="disabledDates"
            :day-cell-content="dayCellContent"
            :format="format"
            :minimum-view="minimumView"
            :maximum-view="maximumView"
            :clear-button="clearButton && !inline"
            bootstrap-styling
            v-on="$listeners"
            @changedMonth="attachEventListeners"
        ></datepicker>
        <b-tooltip
            v-if="dayTooltip && dayTooltipMessage"
            show
            :target="dayTooltip.target"
            triggers="hover"
            variant="danger"
            placement="top"
        >
            <div v-html="dayTooltipMessage"></div>
        </b-tooltip>
    </div>
</template>
<script lang="ts">
import Vue from "vue";
import Datepicker from "vuejs-datepicker";
export default Vue.extend({
    components: {
        Datepicker,
    },
    props: {
        /**
         * Must be unique calendar identifier for the page.
         **/
        calendarClassMain: {
            type: String,
            required: true,
            default: "",
        },
        inputClass: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        wrapperClass: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        calendarClass: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        datepickerIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        inline: {
            type: Boolean,
            required: false,
            default: true,
        },
        clearButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        minimumView: {
            type: String,
            required: false,
            default: "day",
        },
        maximumView: {
            type: String,
            required: false,
            default: "year",
        },
        format: {
            type: String,
            required: false,
            default: "dd MMM yyyy",
        },
        selectedToDate: {
            type: Date,
            required: false,
            default: null,
        },
        selectedFromDate: {
            type: Date,
            required: false,
            default: null,
        },
        /**
         * Example:
         *
         * We define one of the existing parameters for the disabledDates object datepicker and then we add same name parameter with the concatenation of 'messages' to it.
         * The logic works that way that if the parameter is defined in the disabledDates object, then the message will be shown if the date is disabled.
         *
            * disabledDatesConfig: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        days: [0, 6],
                        daysMessage: "This date is disabled V1.",

                        daysOfMonth: [29, 30, 31],
                        daysOfMonthMessage: "This date is disabled V2.",

                        customPredictor: function (date: Date) {
                            if (date.getDate() % 5 === 0) {
                                return true;
                            }
                        },
                        customPredictorMessage: "This date is disabled V3.",

                        dates: [
                            // Disable an array of dates
                            new Date(2016, 9, 16),
                            new Date(2016, 9, 17),
                            new Date(2016, 9, 18),
                        ],
                        ranges: [
                            {
                                // Disable dates in given ranges (exclusive).
                                from: new Date(2016, 11, 25),
                                to: new Date(2016, 11, 30),
                            },
                            {
                                from: new Date(2017, 1, 12),
                                to: new Date(2017, 2, 25),
                            },
                        ],
                        to: new Date(2016, 0, 5), // Disable all dates up to specific date
                        from: new Date(2016, 0, 26), // Disable all dates after specific date

                        disable92daysFromSelected: true,
                        disable92daysFromSelectedMessage: "This date is disabled V4.",
                    };
                },
            },
         */
        disabledDatesConfig: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            dayTooltip: {
                target: null,
            } as any,
            dayTooltipMessage: "",
        };
    },
    computed: {
        calendarClassMerged(): Record<string, any> {
            const calendarClasses = {
                "atx-date-picker-calendar": true,
                "p-2 border-radius-12": this.datepickerIcon,
                ...this.calendarClass,
            };
            if (this.calendarClassMain !== "") {
                calendarClasses[this.calendarClassMain] = true;
            }
            return calendarClasses;
        },
        disabledDates(): Record<any, any> {
            const newObj = Object.assign({});
            for (const key in this.disabledDatesConfig) {
                if (key.indexOf("Message") === -1) {
                    newObj[key] = this.disabledDatesConfig[key];
                }

                if (key === "disable92daysFromSelected" && this.selectedFromDate) {
                    const date = new Date(this.selectedFromDate);
                    date.setDate(date.getDate() + 91); // 91 because we include the selected date

                    newObj.from = date;
                }
            }
            return newObj;
        },
    },
    mounted() {
        // Add a delay to attach the event listeners for the disabled days when component is mounted
        if (Object.keys(this.disabledDatesConfig).length > 0) {
            setTimeout(() => {
                this.attachEventListeners();
            }, 1000);
        }
    },
    methods: {
        setDisabledDatesData(disabledDatesConfig: any, day: any) {
            const tooltipData = {
                target: `day-${day.date}`,
                message: "",
            };

            if (
                disabledDatesConfig.days &&
                disabledDatesConfig.days.includes(new Date(day.timestamp).getDay())
            ) {
                tooltipData.message = disabledDatesConfig.daysMessage;
            }

            if (
                disabledDatesConfig.daysOfMonth &&
                disabledDatesConfig.daysOfMonth.includes(day.date)
            ) {
                tooltipData.message += disabledDatesConfig.daysOfMonthMessage;
            }

            if (
                disabledDatesConfig.customPredictor &&
                disabledDatesConfig.customPredictor(new Date(day.timestamp))
            ) {
                tooltipData.message += disabledDatesConfig.customPredictorMessage;
            }

            if (disabledDatesConfig.disable92daysFromSelected && this.selectedFromDate) {
                const date = new Date(this.selectedFromDate);
                date.setDate(date.getDate() + 91);
                if (new Date(day.timestamp) >= date) {
                    tooltipData.message += disabledDatesConfig.disable92daysFromSelectedMessage;
                }
            }

            return tooltipData;
        },
        dayCellContent(day: Record<string, string>) {
            // We only extend the day cell content if there are disabled dates
            if (Object.keys(this.disabledDatesConfig).length === 0) return day.date;

            const tooltipData = this.setDisabledDatesData(this.disabledDatesConfig, day);
            return `<span id="${this.calendarClassMain}-day-${day.date}" class="day-disabled-data" data-title="${tooltipData.message}" data-selector="${this.calendarClassMain}-${tooltipData.target}" >${day.date}</span>`;
        },
        attachEventListeners() {
            // Attach event listeners to the disabled days
            if (Object.keys(this.disabledDatesConfig).length > 0) {
                this.$nextTick(() => {
                    (this.$refs["atx-date-picker"] as Vue)?.$el
                        .querySelectorAll(
                            `.${this.calendarClassMain}.vdp-datepicker__calendar .cell.day.disabled .day-disabled-data`
                        )
                        .forEach((el: any) => {
                            el.addEventListener("mouseenter", () => {
                                this.dayTooltip.target = el.attributes["data-selector"].value;
                                this.dayTooltipMessage = el.attributes["data-title"].value;
                            });
                            el.addEventListener("mouseleave", () => {
                                this.dayTooltip.target = null;
                                this.dayTooltipMessage = "";
                            });
                        });
                });
            }
        },
    },
});
</script>
