<template>
    <b-button
        :class="{
            'min-width-8': fixed,
        }"
        v-bind="$attrs"
        :disabled="isLoading || $attrs.disabled"
        :variant="variant === '' ? 'atx-blue-2' : variant"
        :size="size"
        v-on="$listeners"
    >
        <template v-if="isLoading">
            <span class="d-block h-0 invisible"><slot></slot></span>
            <b-spinner
                small
                :variant="variant === '' ? 'atx-blue-2' : variant"
                label="Loading..."
            ></b-spinner>
        </template>
        <template v-else>
            <template v-if="$scopedSlots.default">
                <span
                    v-if="icon !== '' && iconLeft && !iconRight"
                    class="d-inline-block"
                >
                    <atx-icon
                        icon-left
                        :icon="icon"
                        space-right
                        :class="iconClass"
                    />
                </span>
                <span :class="`d-inline-block ${textClass}`"><slot></slot></span>
                <span
                    v-if="icon !== '' && iconRight"
                    class="d-inline-block"
                >
                    <atx-icon
                        :icon="icon"
                        space-left
                        :class="iconClass"
                    />
                </span>
            </template>
            <template v-else>Default Text</template>
        </template>
    </b-button>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        variant: {
            type: String,
            default: "",
            required: false,
        },
        size: {
            type: String,
            default: "md",
        },
        isLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
        icon: {
            type: String,
            default: "",
            required: false,
        },
        fixed: {
            type: Boolean,
            default: false,
            required: false,
        },
        iconLeft: {
            type: Boolean,
            default: true,
            required: false,
        },
        iconRight: {
            type: Boolean,
            default: false,
            required: false,
        },
        textClass: {
            type: String,
            default: "",
            required: false,
        },
        iconClass: {
            type: String,
            default: "",
            required: false,
        },
    },
});
</script>

<style lang="scss" scoped>
.min-width-8 {
    min-width: 8rem;
}

.h-0 {
    height: 0;
}
</style>
