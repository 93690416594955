<template>
    <b-row>
        <b-col>
            <atx-pagination-controls
                v-if="totalRows"
                :total-rows="totalRows"
                :current-page="currentPageLocal"
                :per-page="perPageLocal"
                :page-options="pageOptions"
                @updatePerPage="changePerPageHandler"
            />
        </b-col>
        <b-col>
            <atx-pagination-slim
                :total-rows="totalRows"
                :current-page="currentPageLocal"
                :per-page="perPageLocal"
                @change="changeCurrentPageHandler"
            />
        </b-col>
    </b-row>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        perPage: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        totalRows: {
            type: Number,
            required: true,
        },
        pageOptions: {
            type: Array,
        },
    },
    data() {
        return {
            perPageLocal: this.perPage,
            currentPageLocal: this.currentPage,
        };
    },
    methods: {
        changeCurrentPageHandler(eventValue: number) {
            this.currentPageLocal = eventValue;
            this.$emit("changeCurrentPage", eventValue);
        },
        changePerPageHandler(eventValue: number) {
            this.perPageLocal = eventValue;
            this.$emit("changePerPage", eventValue);
        },
    },
});
</script>
