
<div class="bg-atx-orange-2 d-flex flex-row justify-content-center align-items-center p-2">
    <strong class="p-2">You’re in trial mode.</strong>
    <span class="p-2 d-md-block d-none">
        {{ trialBannerText }}
    </span>
    <atx-button
        variant="primary"
        size="sm"
        :href="subscribeUrl"
        class="mx-2"
        @click="choosePlanHandler"
    >
        Choose Plan
    </atx-button>
    <atx-button
        v-b-modal.modal-trial-banner
        variant="outline-dark"
        size="sm"
        class="mx-2"
    >
        Learn More
    </atx-button>
    <atx-modal
        id="modal-trial-banner"
        title="Trial Mode Limitations"
        size="lg"
        centered
        ok-title="Choose plan"
        cancel-title="Close"
        @ok="okHandler"
    >
        <p>
            {{ bannerTextFirstSentence }} You can connect your accounting system to preview the
            entire process with a trial account.
        </p>
        <p>{{ bannerTextSecondSentence }}</p>
    </atx-modal>
</div>
