/**
 * List of all Route constants should be kept in this file.
 */

// Review for later usage instead of typing name every time
// function addCommonGetter<T>(obj: Record<string, T>): void {
//     Object.defineProperty(obj, "COMMON_GETTER", {
//         get: function (this: T) {
//             return Object.keys(obj).find((key) => obj[key] === this) || "";
//         },
//     });
// }

export const REGEX_ROUTES = {
    CONNECT_SALES_CHANNEL: {
        regex: "/admin/sales-channel/(amazon|shopify|walmart|bigcommerce|etsy|ebay|paypal)/connect",
    },
    CONNECT_ACCOUNTING: {
        regex: "/admin/accounting/(qbo|xero|sage|netsuite)",
    },
    SALES_CHANNEL_OR_ACCOUNTING_CALLBACK: {
        regex: "/admin/(sales-channel|accounting|sales-source)/(amazon|shopify|walmart|bigcommerce|etsy|ebay|paypal|qbo|xero|sage|netsuite)/(cb|bank-account-settings|financial-settings|access-token)",
    },
};

export const ADMIN_PAGE_ROUTES = {
    ADMIN: {
        name: "ADMIN",
        path: "/admin",
        fullPath: "/admin",
    },
    APP_ADMIN: {
        name: "APP_ADMIN",
        path: "/app/admin",
        fullPath: "/app/admin",
    },
    ACCOUNTING: {
        name: "ACCOUNTING",
        path: "accounting",
        fullPath: "/admin/accounting",
    },
    STEPPER_CONNECT_TO_WALMART: {
        name: "STEPPER_CONNECT_TO_WALMART",
        path: "sales-channel/walmart",
        fullPath: "/admin/sales-channel/walmart",
    },
    STEPPER_CONNECT_TO_EBAY: {
        name: "STEPPER_CONNECT_TO_EBAY",
        path: "sales-channel/ebay",
        fullPath: "/admin/sales-channel/ebay",
    },
    STEPPER_CONNECT_TO_ETSY: {
        name: "STEPPER_CONNECT_TO_ETSY",
        path: "sales-channel/etsy",
        fullPath: "/admin/sales-channel/etsy",
    },
    STEPPER_CONNECT_TO_SHOPIFY: {
        name: "STEPPER_CONNECT_TO_SHOPIFY",
        path: "sales-channel/shopify",
        fullPath: "/admin/sales-channel/shopify",
    },
    STEPPER_CONNECT_TO_AMAZON: {
        name: "STEPPER_CONNECT_TO_AMAZON",
        path: "sales-channel/amazon",
        fullPath: "/admin/sales-channel/amazon",
    },
    STEPPER_CONNECT_TO_BIGCOMMERCE: {
        name: "STEPPER_CONNECT_TO_BIGCOMMERCE",
        path: "sales-channel/bigcommerce",
        fullPath: "/admin/sales-channel/bigcommerce",
    },
    CONNECT_XERO: {
        name: "CONNECT_XERO",
        path: "xero",
        fullPath: "/admin/accounting/xero",
    },
    XERO_CALLBACK: {
        name: "XERO_CALLBACK",
        path: "xero/cb",
        fullPath: "/admin/accounting/xero/cb",
    },
    CONNECT_QUICKBOOKS: {
        name: "CONNECT_QUICKBOOKS",
        path: "qbo",
        fullPath: "/admin/accounting/qbo",
    },
    QUICKBOOKS_CALLBACK: {
        name: "QUICKBOOKS_CALLBACK",
        path: "qbo/cb",
        fullPath: "/admin/accounting/qbo/cb",
    },
    QUICKBOOKS_BANK_ACCOUNT_SETTINGS: {
        name: "QUICKBOOKS_BANK_ACCOUNT_SETTINGS",
        path: "qbo/bank-account-settings",
        fullPath: "/admin/accounting/qbo/bank-account-settings",
    },
    CONNECT_SAGE: {
        name: "CONNECT_SAGE",
        path: "sage",
        fullPath: "/admin/accounting/sage",
    },
    SAGE_CALLBACK: {
        name: "SAGE_CALLBACK",
        path: "sage/cb",
        fullPath: "/admin/accounting/sage/cb",
    },
    SAGE_FINANCIAL_SETTINGS: {
        name: "SAGE_FINANCIAL_SETTINGS",
        path: "sage/financial-settings",
        fullPath: "/admin/accounting/sage/financial-settings",
    },
    CONNECT_NETSUITE: {
        name: "CONNECT_NETSUITE",
        path: "netsuite",
        fullPath: "/admin/accounting/netsuite",
    },
    EBAY_CALLBACK: {
        name: "EBAY_CALLBACK",
        path: "ebay/cb",
        fullPath: "/admin/sales-channel/ebay/cb",
    },
    ETSY_CALLBACK: {
        name: "ETSY_CALLBACK",
        path: "etsy/cb",
        fullPath: "/admin/sales-channel/etsy/cb",
    },
    SHOPIFY_CALLBACK: {
        name: "SHOPIFY_CALLBACK",
        path: "shopify/cb",
        fullPath: "/admin/sales-channel/shopify/cb",
    },
    AMAZON_CALLBACK: {
        name: "AMAZON_CALLBACK",
        path: "amazon/cb",
        fullPath: "/admin/sales-channel/amazon/cb",
    },
    BIGCOMMERCE_CALLBACK: {
        name: "BIGCOMMERCE_CALLBACK",
        path: "bigcommerce/cb",
        fullPath: "/admin/sales-channel/bigcommerce/cb",
    },
    AUTH_TOKEN: {
        name: "AUTH_TOKEN",
        path: "auth-token",
        fullPath: "/admin/auth-token",
    },
    REQUEST_ACCESS: {
        name: "REQUEST_ACCESS",
        path: "request-access",
        fullPath: "/admin/request-access",
    },
    CONNECT_SHOPIFY: {
        name: "CONNECT_SHOPIFY",
        path: "shopify/connect",
        fullPath: "/admin/sales-channel/shopify/connect",
    },
    INSTALL_SHOPIFY: {
        name: "INSTALL_SHOPIFY",
        path: "shopify/install",
        fullPath: "/admin/sales-channel/shopify/install",
    },
    CONNECT_AMAZON: {
        name: "CONNECT_AMAZON",
        path: "amazon/connect",
        fullPath: "/admin/sales-channel/amazon/connect",
    },
    BIGCOMMERCE_ACCESS_TOKEN: {
        name: "BIGCOMMERCE_ACCESS_TOKEN",
        path: "bigcommerce/access-token",
        fullPath: "/admin/sales-channel/bigcommerce/access-token",
    },
    CONNECT_BIGCOMMERCE: {
        name: "CONNECT_BIGCOMMERCE",
        path: "bigcommerce/connect",
        fullPath: "/admin/sales-channel/bigcommerce/connect",
    },
    SALES_SOURCE: {
        name: "SALES_SOURCE",
        path: "sales-source",
        fullPath: "/admin/sales-source",
    },
    SALES_CHANNEL: {
        name: "SALES_CHANNEL",
        path: "sales-channel",
        fullPath: "/admin/sales-channel",
    },
} as const;

export const INVENTORY_PAGE_ROUTES = {
    INVENTORY: {
        name: "INVENTORY",
        path: "/inventory",
        fullPath: "/inventory",
    },
    INVENTORY_LOCATIONS: {
        name: "INVENTORY_LOCATIONS",
        path: "/inventory-locations",
        fullPath: "/inventory-locations",
    },
} as const;

export const COSTS_PAGE_ROUTES = {
    COSTS: {
        name: "COSTS",
        path: "/cost-admin",
        fullPath: "/cost-admin",
    },
} as const;

export const USER_SETTINGS_PAGE_ROUTES = {
    USER_SETTINGS: {
        name: "USER_SETTINGS",
        path: "/user/settings",
        fullPath: "/user/settings",
    },
    BILLING: {
        name: "BILLING",
        path: "billing",
        fullPath: "/user/settings/billing",
    },
    FIRM_BILLING: {
        name: "FIRM_BILLING",
        path: "firm-billing",
        fullPath: "/user/settings/firm-billing",
    },
    NOTIFICATIONS: {
        name: "NOTIFICATIONS",
        path: "/notifications",
        fullPath: "/notifications",
    },
    PROFILE: {
        name: "PROFILE",
        path: "profile",
        fullPath: "/user/settings/profile",
    },
    SUBSCRIPTIONS: {
        name: "SUBSCRIPTIONS",
        path: "subscriptions",
        fullPath: "/user/settings/subscriptions",
    },
    PARTNER_SUBSCRIPTIONS: {
        name: "PARTNER_SUBSCRIPTIONS",
        path: "partner-subscriptions",
        fullPath: "/user/settings/partner-subscriptions",
    },
    SETTINGS_NOTIFICATIONS: {
        name: "USER_SETTINGS_NOTIFICATIONS",
        path: "notifications",
        fullPath: "/user/settings/notifications",
    },
} as const;

export const BILLING_PAGE_ROUTES = {
    BILLING: {
        name: "BILLING",
        path: "/billing",
        fullPath: "/billing",
    },
    CHECKOUT: {
        name: "CHECKOUT",
        path: "/billing/checkout",
        fullPath: "/billing/checkout",
    },
    CHECKOUT_COMPLETE: {
        name: "CHECKOUT_COMPLETE",
        path: "/billing/checkout/complete",
        fullPath: "/billing/checkout/complete",
    },
    BILLING_MIGRATION: {
        name: "BILLING_MIGRATION",
        path: "/billing/migration",
        fullPath: "/billing/migration",
    },
} as const;

export const SUBSCRIPTION_PAGE_ROUTES = {
    SUBSCRIBE: {
        name: "SUBSCRIBE",
        path: "/subscribe",
        fullPath: "/subscribe",
    },
} as const;

export const ACCOUNT_SETTINGS_PAGE_ROUTES = {
    ACCOUNT_SETTINGS: {
        name: "ACCOUNT_SETTINGS",
        path: "/",
        fullPath: "/account-settings",
    },
    ACCOUNT: {
        name: "ACCOUNT",
        path: "/",
        fullPath: "/account-settings/account",
    },
    PRODUCT_TYPES: {
        name: "PRODUCT_TYPES",
        path: "account-settings/product-types",
        fullPath: "/account-settings/product-types",
    },
    INVOICES: {
        name: "INVOICES",
        path: "account-settings/invoices",
        fullPath: "/account-settings/invoices",
    },
    STATEMENTS_ACCOUNTS_AND_TAXES: {
        name: "STATEMENTS_ACCOUNTS_AND_TAXES",
        path: "account-settings/statements-accounts-and-taxes",
        fullPath: "/account-settings/statements-accounts-and-taxes",
    },
    COGS: {
        name: "COGS",
        path: "account-settings/cogs",
        fullPath: "/account-settings/cogs",
    },
    AUTOMATION: {
        name: "AUTOMATION",
        path: "account-settings/automation",
        fullPath: "/account-settings/automation",
    },
    INVENTORY: {
        name: "INVENTORY",
        path: "account-settings/inventory",
        fullPath: "/account-settings/inventory",
    },
    USERS: {
        name: "USERS",
        path: "account-settings/users",
        fullPath: "/account-settings/users",
    },
    BETA_FEATURES: {
        name: "BETA_FEATURES",
        path: "account-settings/new-features",
        fullPath: "/account-settings/new-features",
    },
    BILLING: {
        name: "BILLING",
        path: "account-settings/billing",
        fullPath: "/account-settings/billing",
    },
    REGISTERED_COUNTRY: {
        name: "REGISTERED_COUNTRY",
        path: "account-settings/registered-country",
        fullPath: "/account-settings/registered-country",
    },
    AMAZON_PAY: {
        name: "AMAZON_PAY",
        path: "account-settings/amazon-pay",
        fullPath: "/account-settings/amazon-pay",
    },
    HISTORY: {
        name: "HISTORY",
        path: "account-settings/history",
        fullPath: "/account-settings/history",
    },
    NETSUITE_CONFIGURATION: {
        name: "NETSUITE_CONFIGURATION",
        path: "account-settings/netsuite-configuration",
        fullPath: "/account-settings/netsuite-configuration",
    },
    CONNECTIONS: {
        name: "CONNECTIONS",
        path: "/account-settings/connections",
        fullPath: "/account-settings/connections",
    },
    BACKEND: {
        name: "BACKEND",
        path: "backend",
        fullPath: "/backend",
    },
    PAYPAL: {
        name: "PAYPAL",
        path: "/account-settings/paypal",
        fullPath: "/account-settings/paypal",
    },
    SHOPIFY_B2B: {
        name: "SHOPIFY B2B",
        path: "/account-settings/shopify-b2b",
        fullPath: "/account-settings/shopify-b2b",
    },
} as const;

export const SETTLEMENT_PAGE_ROUTES = {
    SETTLEMENT: {
        name: "SETTLEMENT",
        path: "/settlement",
        fullPath: "/settlement",
    },
    SETTLEMENTS: {
        name: "SETTLEMENTS",
        path: "/settlements",
        fullPath: "/settlements",
    },
    B2B_SETTLEMENTS: {
        name: "B2B_SETTLEMENTS",
        path: "/b2b-settlements",
        fullPath: "/b2b-settlements",
    },
    APP_SETTLEMENTS: {
        name: "APP_SETTLEMENTS",
        path: "/app/settlements",
        fullPath: "/app/settlements",
    },
    APP_SETTLEMENT: {
        name: "APP_SETTLEMENT",
        path: "/app/settlement",
        fullPath: "/app/settlement",
    },
    APP_B2B_SETTLEMENTS: {
        name: "APP_B2B_SETTLEMENTS",
        path: "/app/b2b-settlements",
        fullPath: "/app/b2b-settlements",
    },
    EXPORT_SETTLEMENT: {
        name: "EXPORT_SETTLEMENT",
        path: "/export-settlement",
        fullPath: "/export-settlement",
    },
    EXPORT_COGS: {
        name: "EXPORT_COGS",
        path: "/export-cogs",
        fullPath: "/export-cogs",
    },
} as const;

export const MAPPING_PAGE_ROUTES = {
    APP_MAPPINGS: {
        name: "APP_MAPPINGS",
        path: "/app/mapping",
        fullPath: "/app/mapping",
    },
    MAPPING: {
        name: "MAPPING",
        path: "/mapping",
        fullPath: "/mapping",
    },
    // Mapping optimized is referring to a older JSP implementation
    MAPPING_OPT: {
        name: "MAPPING_OPT",
        path: "/mapping-opt",
        fullPath: "/mapping-opt",
    },
} as const;

export const REPORTS_PAGE_ROUTES = {
    APP_EXPORT_DATA: {
        name: "EXPORT_DATA",
        path: "/reports/export-data",
        fullPath: "/app/reports/export-data",
    },
} as const;

export const SHOPIFY_PAGE_ROUTES = {
    FINANCE_REPORT: {
        name: "FINANCE_REPORT",
        path: "/shopify/financereport",
        fullPath: "/shopify/financereport",
    },
    GIFT_CARDS_REPORT: {
        name: "GIFT_CARDS_REPORT",
        path: "/shopify/giftcards",
        fullPath: "/shopify/giftcards",
    },
    ORDER: {
        name: "ORDER",
        path: "/shopify/order",
        fullPath: "/shopify/order",
    },
    RECONCILIATION_REPORTS: {
        name: "RECONCILIATION_REPORTS",
        path: "/reconciliation-reports",
        fullPath: "/reconciliation-reports",
    },
    RECONCILIATION_REPORT: {
        name: "RECONCILIATION_REPORT",
        path: "/reconciliation-reports/report",
        fullPath: "/reconciliation-reports/report",
    },
    RECONNECT: {
        name: "ShopifyReconnect",
        path: "/shopify/reconnect",
        fullPath: "/shopify/reconnect",
    },
    SINGLE_ORDER_INVOICE_REPORT: {
        name: "B2B_INVOICE_REPORT",
        path: "/shopify/b2b-invoice-report",
        fullPath: "/shopify/b2b-invoice-report",
    },
} as const;

export const AMAZON_PAGE_ROUTES = {
    DATE_RANGE_SUMMARY_REPORT: {
        name: "DATE_RANGE_SUMMARY_REPORT",
        path: "/amazon/date-range-summary-report",
        fullPath: "/amazon/date-range-summary-report",
    },
    AMAZON_SELLER_CENTRAL_REPORTS_REPOSITORY: {
        name: "AMAZON_SELLER_CENTRAL_REPORTS_REPOSITORY",
        path: "https://sellercentral.amazon.com/payments/reports-repository/",
        fullPath: "https://sellercentral.amazon.com/payments/reports-repository/",
    },
    AMAZON_DISCREPANCY_SUPPORT_ARTICLE: {
        name: "AMAZON_DISCREPANCY_SUPPORT_ARTICLE",
        path: "https://support.a2xaccounting.com/en/articles/8871866-using-a2x-s-amazon-summary-report#:~:text=Multi%2Dchannel%20fulfillment%3A%20Note%20that%20transactions%20for%20orders%20fulfilled%20by%20Amazon%2C%20but%20not%20made%20on%20Amazon%20are%20currently%20excluded%20from%20reports",
        fullPath:
            "https://support.a2xaccounting.com/en/articles/8871866-using-a2x-s-amazon-summary-report#:~:text=Multi%2Dchannel%20fulfillment%3A%20Note%20that%20transactions%20for%20orders%20fulfilled%20by%20Amazon%2C%20but%20not%20made%20on%20Amazon%20are%20currently%20excluded%20from%20reports",
    },
} as const;

export const WEBSITE_LINKS = {
    ECOMMERCE_ACCOUNTANT_DIRECTORY: {
        name: "ECOMMERCE_ACCOUNTANT_DIRECTORY",
        path: "https://www.a2xaccounting.com/ecommerce-accountant-directory",
    },
    WHATS_NEW: {
        name: "WHATS_NEW",
        path: "/whats-new",
    },
} as const;

export const SUPPORT_LINKS = {
    SUPPORT: {
        name: "SUPPORT",
        path: "https://support.a2xaccounting.com/en",
    },
    SUPPORT_ARTICLE_HOW_TO_ADD_ACCOUNT_QBO_ONLINE: {
        name: "SUPPORT_ARTICLE_HOW_TO_ADD_ACCOUNT_QBO_ONLINE",
        path: "https://support.a2xaccounting.com/en/articles/1993171-how-to-add-a-bank-account-in-quickbooks-online",
    },
    SUPPORT_VAT_MAPPING_FOR_SAGE_ONLINE: {
        name: "SUPPORT_VAT_MAPPING_FOR_SAGE_ONLINE",
        path: "https://support.a2xaccounting.com/en/articles/6515411-vat-mapping-for-sage-online-users",
    },
    SUPPORT_HOW_TO_FIND_YOUR_BIGCOMMERCE_STORE_HASH: {
        name: "SUPPORT_HOW_TO_FIND_YOUR_BIGCOMMERCE_STORE_HASH",
        path: "https://support.a2xaccounting.com/en/articles/6396583-how-to-find-your-bigcommerce-store-hash-and-access-token",
    },
    SUPPORT_HOW_TO_FIND_YOUR_SHOPIFY_STORE_NAME: {
        name: "SUPPORT_HOW_TO_FIND_YOUR_SHOPIFY_STORE_NAME",
        path: "https://support.a2xaccounting.com/en/articles/6396584-how-to-find-your-shopify-store-name",
    },
    SUPPORT_HOW_DO_I_POST_MY_PAYOUT_TO_QBO_XERO_OR_SAGE: {
        name: "SUPPORT_HOW_DO_I_POST_MY_PAYOUT_TO_QBO_XERO_OR_SAGE",
        path: "https://support.a2xaccounting.com/en/articles/6997538-how-do-i-post-my-payout-to-quickbooks-online-xero-netsuite-or-sage",
    },
    SUPPORT_HOW_TO_FIND_YOUR_WALMART_CLIENT_ID_AND_CLIENT_SECRET: {
        name: "SUPPORT_HOW_TO_FIND_YOUR_WALMART_CLIENT_ID_AND_CLIENT_SECRET",
        path: "https://support.a2xaccounting.com/en/articles/4321439-how-to-find-your-walmart-client-id-and-client-secret",
    },
    SUPPORT_MAPPING_PAGE_CLEANUP_SCHEDULE: {
        name: "SUPPORT_MAPPING_PAGE_CLEANUP_SCHEDULE",
        path: "https://support.a2xaccounting.com/en/articles/7004103-mapping-page-cleanup-schedule",
    },
    SUPPORT_MISSING_SETTLEMENTS: {
        name: "SUPPORT_MISSING_SETTLEMENTS",
        path: "https://support.a2xaccounting.com/faqs/why-are-some-of-my-settlement-files-missing",
    },
    SUPPORT_HOW_DO_I_GET_STARTED_WITH_BIGCOMMERCE: {
        name: "SUPPORT_HOW_DO_I_GET_STARTED_WITH_BIGCOMMERCE",
        path: "https://support.a2xaccounting.com/en/articles/6412611-how-do-i-get-started-with-a2x-for-bigcommerce-connect-to-your-bigcommerce-account",
    },
    SUPPORT_HOW_DO_I_GET_STARTED_WITH_AMAZON: {
        name: "SUPPORT_HOW_DO_I_GET_STARTED_WITH_AMAZON",
        path: "https://support.a2xaccounting.com/en/articles/959792-how-do-i-get-started-with-a2x-for-amazon-connect-to-an-amazon-marketplace",
    },
    SUPPORT_HOW_DO_I_GET_STARTED_WITH_WALMART: {
        name: "SUPPORT_HOW_DO_I_GET_STARTED_WITH_WALMART",
        path: "https://support.a2xaccounting.com/en/articles/4300778-how-do-i-get-started-with-a2x-for-walmart-connect-to-walmart",
    },
    SUPPORT_HOW_TO_POST_AND_RECONCILE: {
        name: "SUPPORT_HOW_TO_POST_AND_RECONCILE",
        path: "https://support.a2xaccounting.com/en/articles/6997538-how-do-i-post-my-payout-to-quickbooks-online-xero-netsuite-or-sage",
    },
    SUPPORT_HOW_TO_RECONCILE_CLEARING_ACCOUNTS: {
        name: "SUPPORT_HOW_TO_RECONCILE_CLEARING_ACCOUNTS",
        path: "https://support.a2xaccounting.com/en/articles/6041146-a2x-for-shopify-using-clearing-accounts-for-other-payment-gateways",
    },
    SUPPORT_HOW_TO_TRANSFER_BILLING_TO_ANOTHER_USER: {
        name: "SUPPORT_HOW_TO_TRANSFER_BILLING_TO_ANOTHER_USER",
        path: "https://support.a2xaccounting.com/en/articles/8684759-how-to-transfer-your-billing-from-one-user-to-another",
    },
    SUPPORT_HOW_TO_TRANSFER_BILLING_TO_AND_FROM_A_CLIENT: {
        name: "SUPPORT_HOW_TO_TRANSFER_BILLING_TO_AND_FROM_A_CLIENT",
        path: "https://support.a2xaccounting.com/en/articles/8681478-how-to-transfer-billing-to-and-from-a-client-if-you-are-an-accounting-firm",
    },
    SUPPORT_HOW_TO_DISCONNECT_SHOPIFY_STORE: {
        name: "SUPPORT_HOW_TO_DISCONNECT_SHOPIFY_STORE",
        path: "https://support.a2xaccounting.com/en/articles/3945811-disconnecting-your-shopify-store-in-a2x-what-happens",
    },
    SUPPORT_INTRODUCING_NEW_EXPORTS: {
        name: "SUPPORT_INTRODUCING_NEW_EXPORTS",
        path: "https://support.a2xaccounting.com/en/articles/9827498-introducing-the-new-exports-feature-simplify-your-financial-reconciliation",
    },
    SUPPORT_PAYPAL_HOW_TO_CONNECT: {
        name: "SUPPORT_PAYPAL_HOW_TO_CONNECT",
        path: "https://support.a2xaccounting.com/en/articles/10287431-how-do-i-get-started-with-a2x-for-paypal-step-2-connect-to-your-paypal-account-to-a2x",
    },
    SUPPORT_PAYPAL_HOW_TO_CONNECT_V2: {
        name: "SUPPORT_PAYPAL_HOW_TO_CONNECT_V2",
        path: "https://support.a2xaccounting.com/en/articles/10287431-a2x-for-paypal-connect-to-your-paypal-account-to-a2x",
    },
    SUPPORT_PAYPAL_GET_STARTED: {
        name: "SUPPORT_PAYPAL_GET_STARTED",
        path: "https://support.a2xaccounting.com/en/articles/10434301-getting-started-with-a2x-for-paypal",
    },
    SUPPORT_NETSUITE_CONNECT: {
        name: "SUPPORT_NETSUITE_CONNECT",
        path: "https://support.a2xaccounting.com/en/articles/7211660-connecting-a2x-to-netsuite",
    },
    SUPPORT_XERO_CONNECT: {
        name: "SUPPORT_XERO_CONNECT",
        path: "https://support.a2xaccounting.com/en/articles/5920731-connect-a2x-to-your-accounting-system",
    },
    SUPPORT_PAYPAL_HOW_TO_POST_AND_RECONCILE: {
        name: "SUPPORT_PAYPAL_HOW_TO_POST_AND_RECONCILE",
        path: "https://support.a2xaccounting.com/en/articles/10288379-a2x-for-paypal-using-clearing-accounts-for-paypal-categories",
    },
    SUPPORT_PAYPAL_HOW_TO_SETUP_ACCOUNTS_AND_TAXES: {
        name: "SUPPORT_PAYPAL_HOW_TO_SETUP_ACCOUNTS_AND_TAXES",
        path: "https://support.a2xaccounting.com/en/articles/10288397-how-do-i-get-started-with-a2x-for-paypal-step-4-set-up-your-accounts-and-taxes-mapping",
    },
    SUPPORT_GENERAL_HOW_TO_SETUP_ACCOUNTS_AND_TAXES: {
        name: "SUPPORT_GENERAL_HOW_TO_SETUP_ACCOUNTS_AND_TAXES",
        path: "https://support.a2xaccounting.com/en/articles/6134375-accounts-and-taxes-mapping-instructions",
    },
    SUPPORT_PAYPAL_SUPPLIERS_GROUPING: {
        name: "SUPPORT_PAYPAL_SUPPLIERS_GROUPING",
        path: "https://support.a2xaccounting.com/en/articles/9684628-what-are-paypal-payments-sent",
    },
    SUPPORT_PAYPAL_DEPOSITS_AND_CREDITS_GROUPING: {
        name: "SUPPORT_PAYPAL_DEPOSITS_AND_CREDITS_GROUPING",
        path: "https://support.a2xaccounting.com/en/articles/10299693-what-are-paypal-deposits-and-credits",
    },
    SUPPORT_PAYPAL_WITHDRAWALS_AND_DEBITS_GROUPING: {
        name: "SUPPORT_WITHDRAWALS_AND_DEBITS_GROUPING",
        path: "https://support.a2xaccounting.com/en/articles/10307844-what-are-paypal-withdrawals-and-debits",
    },
    SUPPORT_PAYPAL_CATEGORIES_SETTINGS: {
        name: "SUPPORT_PAYPAL_CATEGORIES_SETTINGS",
        path: "https://support.a2xaccounting.com/en/articles/9748026-excluding-a-paypal-category-in-a2x",
    },
    SUPPORT_ASSISTED_SETUP_ACCOUNTS_AND_TAX_MAPPING: {
        name: "SUPPORT_ASSISTED_SETUP_ACCOUNTS_AND_TAX_MAPPING",
        path: "https://support.a2xaccounting.com/en/articles/6443000-assisted-setup-accounts-and-tax-mapping",
    },
    SUPPORT_HOW_TO_SET_UP_PRODUCT_TYPE_GROUPING: {
        name: "SUPPORT_HOW_TO_SET_UP_PRODUCT_TYPE_GROUPING",
        path: "https://support.a2xaccounting.com/en/articles/1631005-how-to-set-up-product-type-grouping",
    },
    SUPPORT_ACCOUNTS_AND_TAXES_MAPPING_INSTRUCTIONS: {
        name: "SUPPORT_ACCOUNTS_AND_TAXES_MAPPING_INSTRUCTIONS",
        path: "https://support.a2xaccounting.com/en/articles/6134375-accounts-and-taxes-mapping-instructions",
    },
    SUPPORT_ENABLE_ADVANCED_MAPPING_RULES: {
        name: "SUPPORT_ENABLE_ADVANCED_MAPPING_RULES",
        path: "https://support.a2xaccounting.com/en/articles/6333427-enable-advanced-mapping-rules-in-a2x-for-amazon-for-uk-vat-oss-ioss-and-au-gst-registered-sellers",
    },
    SUPPORT_PAYPAL_COUNTRY_AND_TIMEZONE: {
        name: "SUPPORT_PAYPAL_COUNTRY_AND_TIMEZONE",
        path: "https://support.a2xaccounting.com/en/articles/10472777-paypal-country-and-time-zone",
    },
    SUPPORT_PAYPAL_ACCOUNTING_SYSTEM_REFERENCE_NAME: {
        name: "SUPPORT_PAYPAL_ACCOUNTING_SYSTEM_REFERENCE_NAME",
        path: "https://support.a2xaccounting.com/en/articles/10472785-a2x-for-paypal-accounting-system-reference-name",
    },
    SUPPORT_PAYPAL_MAPPING_GLOSSARY: {
        name: "SUPPORT_PAYPAL_MAPPING_GLOSSARY",
        path: "https://support.a2xaccounting.com/en/articles/10472813-paypal-categories",
    },
    SUPPORT_SINGLE_ORDER_REPORT_LEARN_MORE: {
        name: "SUPPORT_SINGLE_ORDER_REPORT_LEARN_MORE",
        path: "https://support.a2xaccounting.com/en/articles/10796477-a2x-for-b2b-the-b2b-invoice-report",
    },
    SUPPORT_SINGLE_ORDER_LEARN_MORE: {
        name: "SUPPORT_SINGLE_ORDER_LEARN_MORE",
        path: "https://support.a2xaccounting.com/en/articles/10575305-a2x-for-shopify-b2b-invoice-overview",
    },
    SUPPORT_SINGLE_ORDER_FAQ: {
        name: "SUPPORT_SINGLE_ORDER_FAQ",
        path: "https://support.a2xaccounting.com/en/articles/10796234-a2x-for-b2b-trouble-shooting-and-faq-guide",
    },
    SUPPORT_SINGLE_ORDER_B2B_INVOICE_OVERVIEW: {
        name: "SUPPORT_SINGLE_ORDER_B2B_INVOICE_OVERVIEW",
        path: "https://support.a2xaccounting.com/en/articles/10575305-a2x-for-shopify-b2b-invoice-overview",
    },
    SUPPORT_SINGLE_ORDER_SETTINGS_LEARN_MORE: {
        name: "SUPPORT_SINGLE_ORDER_SETTINGS_LEARN_MORE",
        path: "https://support.a2xaccounting.com/en/articles/10575355-a2x-for-shopify-b2b-enable-b2b-settings",
    },
    SUPPORT_SINGLE_ORDER_ASSET_ACCOUNT_QBO: {
        name: "SUPPORT_SINGLE_ORDER_ASSET_ACCOUNT_QBO",
        path: "https://support.a2xaccounting.com/en/articles/10575594-a2x-for-shopify-b2b-complete-accounts-taxes-qbo",
    },
    SUPPORT_SINGLE_ORDER_ASSET_ACCOUNT_XERO: {
        name: "SUPPORT_SINGLE_ORDER_ASSET_ACCOUNT_XERO",
        path: "https://support.a2xaccounting.com/en/articles/10575432-a2x-for-shopify-b2b-complete-accounts-taxes-xero",
    },
} as const;

export const ACTION_LINKS = {
    SWITCH_ACCOUNT_NEW: {
        name: "SWITCH_ACCOUNT_NEW",
        path: "/switch?account=NEW",
    },
} as const;

export const OMNI_ANALYTICS_PAGES = {
    DASHBOARD: {
        name: "DASHBOARD",
        path: "/analytics/dashboard",
        fullPath: "/analytics/dashboard",
    },
    CUSTOM_ANALYSIS: {
        name: "CUSTOM_ANALYSIS",
        path: "/analytics/custom",
        fullPath: "/analytics/custom",
    },
    DIRECTORY: {
        name: "DIRECTORY",
        path: "/analytics/directory",
        fullPath: "/analytics/directory",
    },
} as const;

export const PARTNER_PAGE_ROUTES = {
    OVERVIEW: {
        name: "OVERVIEW",
        path: "/overview",
        fullPath: "/overview",
    },
    PARTNER_DASHBOARD: {
        name: "PARTNER_DASHBOARD",
        path: "/partner-dashboard",
        fullPath: "/partner-dashboard",
    },
    PARTNER: {
        name: "PARTNER",
        path: "/partner",
        fullPath: "/partner",
    },
    TEAMS: {
        name: "TEAMS",
        path: "/teams",
        fullPath: "/teams",
    },
    TEAM_SETTINGS: {
        name: "TEAM_SETTINGS",
        path: "/team-settings",
        fullPath: "/team-settings",
    },
} as const;

export const INVOICE_PAGE_ROUTES = {
    INVOICES: {
        name: "INVOICES",
        path: "/invoices",
        fullPath: "/invoices",
    },
    INVOICE: {
        name: "INVOICE",
        path: "/invoice",
        fullPath: "/invoice",
    },
};

export const OVERVIEW_PAGE_ROUTES = {
    OVERVIEW_SETTLEMENTS: {
        name: "OVERVIEW_SETTLEMENTS",
        path: "/overview-settlements",
        fullPath: "/overview-settlements",
    },
};

export const OTHER_LINKS = {
    MAILTO_CONTACT: {
        name: "MAILTO_CONTACT",
        path: "mailto:contact@a2xaccounting.com",
    },
    NOT_FOUND: {
        name: "NOT_FOUND",
        path: "/404",
    },
    LOGIN: {
        name: "LOGIN",
        path: "/login",
    },
    UNDEFINED_PATH: {
        name: "UNDEFINED_PATH",
        path: "*",
    },
} as const;

export const EXTERNAL_SHOPIFY_LINKS = {
    A2X_APP_STORE: "https://apps.shopify.com/a2x",
};
