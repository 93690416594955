
<b-button-group
    class="position-static"
    :data-test-id="testId"
>
    <b-dropdown
        ref="atx-dropdown-range-datepicker"
        variant="light"
        class="atx-dropdown mx-2"
        toggle-class="border-radius-4 px-2 py-1"
        menu-class="border-radius-12"
        :disabled="dropdownDisabled"
        boundary="window"
        no-caret
        @show="openFilterSelect"
        @hide="closeFilterSelect"
    >
        <template #button-content>
            <span class="dropdown-toggle-button-text">
                {{ formatRangeSelected }}
            </span>
            <atx-filter-counter-badge
                v-if="badgeCounter > 0"
                :counter="badgeCounter"
            />
            <atx-icon :icon="!filterSelectShouldOpen ? 'chevron-down' : 'chevron-up'" />
        </template>

        <atx-date-range-picker
            :calendar-class-main="calendarClassMain"
            v-bind="$attrs"
            v-on="$listeners"
            @range-selected="rangeSelectedHandler"
            :initial-date-range="initialRange"
        ></atx-date-range-picker>
    </b-dropdown>
</b-button-group>
