import { defineStore } from "pinia";
import {
    AccountingSystem,
    FEATURE_FLAGS,
    IAccountEntity,
    SalesSource,
    SystemSetupStatus,
} from "@/models";
import { SalesSourceInviteStatus } from "@/models";
import {
    capitalize,
    containsErrorResponse,
    getErrorResponse,
    hasAdvancedCogsFeatures,
    isAmazon,
    isManualConnection,
    isNetSuite,
    isPayPal,
    isQboConnection,
    isShopify,
    getSourceDisplayName,
    toast,
    transactionContainer,
    isEbay,
} from "@/helpers";
import { accountApi, featureFlagApi, oauthApi, switchApi } from "@/api";
import { useAuthStore as authStore, useLoaderStore as loaderStore } from "@/stores";
import { isProductionEnv, trackingConfig } from "@/helpers";
import { bootstrap, setOptions } from "vue-gtag";
import {
    SUBSCRIPTION_PAGE_ROUTES,
    SETTLEMENT_PAGE_ROUTES,
    OMNI_ANALYTICS_PAGES,
} from "@/router/routeConstants";
import { FEATURES, sendStandardEvent } from "@/helpers/store";

type AccountState = {
    accountKey: string;
    accountName: string;
    accountFeatures: {
        enableAmazonPay: boolean;
        showAdvancedFba: boolean;
        showGiftCards: boolean;
        stripeEnabled: boolean;
        cogsEnabled: boolean;
        xeroMigratedToA2x2: boolean;
        premium: boolean;
        a2x2OnboardingMappingsEnabled: boolean;
        isAccount60DaysOld: boolean;
        validCopyAccounts: Record<string, unknown>[];
        a2x2BillingSystemEnabled: boolean;
        isDemoAccount: boolean;
    };
    featureFlags: FEATURE_FLAGS[] | null;
    accountingSystem: AccountingSystem;
    accountingSystemConnected: boolean;
    accountingSystemOrganizationName: string;
    partnerCode: string | null;
    planCode: string;
    planAmount: number;
    subscribedAt: number | null;
    salesChannel: SalesSource;
    salesChannelConnected: boolean;
    salesChannelPending: boolean;
    salesChannelInviteStatus: SalesSourceInviteStatus | null;
    salesChannelStoreName: string | null;
    mappingsCompleted: boolean;
    mappingsConfigurationInitiated: boolean;
    firstSettlementPosted: boolean;
    hasAccountsFile: boolean;
    timeZoneId: string;
    templatePrefix: string;
    isPiggyBackingAccount: boolean;
    requestHistoryBlocked: boolean;
    isAmazonSummaryReportEnabled: boolean;
    redactionPending: boolean;
    accountingSystemReferenceName: string;
    isClarityPage: boolean;
};

export const useAccountStore = defineStore({
    id: "account",
    state: () =>
        ({
            accountKey: "",
            accountName: "",
            accountFeatures: {
                enableAmazonPay: false,
                showAdvancedFba: false,
                showGiftCards: false,
                stripeEnabled: false,
                cogsEnabled: false,
                xeroMigratedToA2x2: false,
                premium: false,
                a2x2OnboardingMappingsEnabled: false,
                isAccount60DaysOld: false,
                validCopyAccounts: [],
                a2x2BillingSystemEnabled: false,
                isDemoAccount: false,
            },
            featureFlags: null,
            accountingSystem: "" as AccountingSystem,
            accountingSystemConnected: false,
            accountingSystemOrganizationName: "",
            partnerCode: null,
            planCode: "",
            planAmount: 0,
            subscribedAt: null,
            salesChannel: "" as SalesSource, // we assign type here to allow empty string as default value
            salesChannelConnected: false,
            salesChannelPending: false,
            salesChannelInviteStatus: null,
            salesChannelStoreName: null,
            mappingsCompleted: false,
            mappingsConfigurationInitiated: false,
            firstSettlementPosted: false,
            hasAccountsFile: false,
            timeZoneId: "",
            templatePrefix: "",
            isPiggyBackingAccount: false,
            requestHistoryBlocked: false,
            isAmazonSummaryReportEnabled: false,
            redactionPending: false,
            accountingSystemReferenceName: "",
            isClarityPage: false,
        }) as AccountState,
    getters: {
        getAccountingSystem: (state) => {
            return state.accountingSystem;
        },
        getStepOneStatus: (state): SystemSetupStatus => {
            if (state.accountingSystem === AccountingSystem.NONE) {
                return "INITIAL";
            } else {
                return state.accountingSystemConnected ? "COMPLETED" : "FAILED";
            }
        },
        getStepOneText: (state) => {
            if (state.accountingSystem === AccountingSystem.NONE) {
                return "Connect accounting software";
            } else if (state.accountingSystemConnected) {
                return state.accountingSystem === AccountingSystem.MANUAL
                    ? "Connected manually"
                    : `Connected to ${getSourceDisplayName(state.accountingSystem)}`;
            } else {
                return "Connect to accounting";
            }
        },
        getStepTwoStatus: (state): SystemSetupStatus => {
            if (state.salesChannelPending) {
                return "PENDING";
            }

            return state.salesChannelConnected ? "COMPLETED" : "INITIAL";
        },
        getStepTwoText: (state) => {
            return state.salesChannelConnected
                ? `Connected to ${getSourceDisplayName(state.salesChannel)}`
                : `Connect ${getSourceDisplayName(state.salesChannel)}`;
        },
        getStepThreeStatus: (state) => {
            return state.mappingsCompleted ? "COMPLETED" : "INITIAL";
        },
        getStepThreeText: () => {
            return "Setup accounts and taxes";
        },
        getStepFourStatus: (state) => {
            return state.firstSettlementPosted ? "COMPLETED" : "INITIAL";
        },
        getStepFourText: () => {
            return "Post and reconcile";
        },
        getSalesChannelDisplayName: (state) => {
            return getSourceDisplayName(state.salesChannel);
        },
        getAccountingSystemDisplayName: (state) => {
            return getSourceDisplayName(state.accountingSystem);
        },
        // Returns lowercase for easier comparisons where needed, this way we don't rely on the backend to send the proper casing
        getSalesChannelLowerCase: (state) => {
            return state.salesChannel.toLowerCase();
        },
        getTransactionContainerName(): string {
            if (!this.salesChannel) return "settlements";
            return transactionContainer(this.salesChannel, true);
        },
        isManualConnection(): boolean {
            return isManualConnection(this.accountingSystem);
        },
        isQboConnection(): boolean {
            return isQboConnection(this.accountingSystem);
        },
        isCogsEnabled(state): boolean {
            return state.accountFeatures.cogsEnabled;
        },
        isShopify(state): boolean {
            return isShopify(state.salesChannel);
        },
        isPayPal(state): boolean {
            return isPayPal(state.salesChannel);
        },
        isNetSuite(state): boolean {
            return isNetSuite(state.accountingSystem);
        },
        isAmazon(state): boolean {
            return isAmazon(state.salesChannel);
        },
        isEbay(state): boolean {
            return isEbay(state.salesChannel);
        },
        getActiveStep(): string {
            if (this.accountingSystemConnected) {
                if (!this.salesChannelConnected) {
                    return "2";
                } else if (!this.mappingsCompleted && !this.mappingsConfigurationInitiated) {
                    return "3";
                } else if (!this.firstSettlementPosted || !this.mappingsConfigurationInitiated) {
                    return "4";
                }
            }
            return "1";
        },
        getSubscribeUrl(): string {
            const subscribeUrl = SUBSCRIPTION_PAGE_ROUTES.SUBSCRIBE.path;

            if (this.accountKey === null || this.salesChannel === null) return subscribeUrl;
            if (this.salesChannel.toLowerCase() === "amazon") {
                return `${subscribeUrl}?accountKey=${this.accountKey}`;
            } else {
                return `/${this.salesChannel.toLowerCase()}${subscribeUrl}?accountKey=${
                    this.accountKey
                }`;
            }
        },
        premium(): boolean {
            return this.accountFeatures.premium;
        },
        hasAdvancedCogsFeatures(): boolean {
            return hasAdvancedCogsFeatures(this.isCogsEnabled, this.premium, this.featureFlags);
        },
        getSettlementsPath(): string {
            return SETTLEMENT_PAGE_ROUTES.APP_SETTLEMENTS.fullPath;
        },
        isDemoAccount(state): boolean {
            return state.accountFeatures.isDemoAccount === true;
        },
    },
    actions: {
        async setAccountOverviewAsync() {
            const response = await accountApi.getOverview(this.accountKey);

            if (response.data !== null) {
                const accountingSystemStatus = { ...response.data.accountingSystemStatus };
                const salesSourceStatus = { ...response.data.salesSourceStatus };
                // Save accounting system and sales source
                if (salesSourceStatus && accountingSystemStatus) {
                    this.accountName = response.data.accountName ?? "";
                    this.accountingSystem = accountingSystemStatus.accountingSystem;
                    this.salesChannel = salesSourceStatus.salesSource;
                    this.salesChannelConnected = salesSourceStatus.connected;
                    this.salesChannelPending = salesSourceStatus.pending;
                    this.salesChannelInviteStatus = salesSourceStatus.salesSourceInviteStatus;
                    this.accountingSystemConnected = accountingSystemStatus.connected;
                    this.accountingSystemOrganizationName =
                        accountingSystemStatus.organizationName ?? "";
                    this.mappingsCompleted = response.data.mappingsCompleted;
                    this.mappingsConfigurationInitiated =
                        response.data.mappingsConfigurationInitiated;
                    this.firstSettlementPosted = response.data.firstSettlementPosted;
                    this.hasAccountsFile = response.data.hasAccountsFile;
                }

                // TODO remove this after Shopify re-auth is complete
                if (this.salesChannel === SalesSource.SHOPIFY) {
                    this.salesChannelPending =
                        !salesSourceStatus.connected && salesSourceStatus.pending;
                }
            }
        },
        async updateAccountStoreDataAsync(
            accountData: IAccountEntity,
            isPiggyBackingAccount = false
        ) {
            this.accountKey = accountData.accountKey;
            authStore().setAccountKeyStoreWide(accountData.accountKey);

            await this.setAccountOverviewAsync();
            this.salesChannel = accountData.salesSource as SalesSource;
            this.accountFeatures = {
                enableAmazonPay: accountData.enableAmazonPay,
                showAdvancedFba: accountData.shouldShowAdvancedFBA,
                showGiftCards: accountData.showGiftCards,
                stripeEnabled: true, // Always show stripe
                cogsEnabled: accountData.cogsEnabled,
                xeroMigratedToA2x2: accountData.xeroMigratedToA2x2,
                premium: accountData.premium,
                a2x2OnboardingMappingsEnabled: accountData.a2X2OnBoardingMappingsEnabled,
                isAccount60DaysOld: accountData.isAccount60DaysOld,
                validCopyAccounts: [],
                a2x2BillingSystemEnabled: accountData.a2x2BillingSystemEnabled ?? false,
                isDemoAccount: accountData.isDemoAccount,
            };

            this.planAmount = accountData.planAmount ? parseInt(accountData.planAmount) : 0;
            this.subscribedAt = accountData.subscribedAt ?? null;
            this.partnerCode = accountData.partnerCode ?? null;
            this.timeZoneId = accountData.timeZoneId;
            this.salesChannelStoreName = accountData.salesSourceDisplay;
            this.isPiggyBackingAccount = isPiggyBackingAccount;
            this.requestHistoryBlocked = accountData.requestHistoryBlocked;
            this.featureFlags = accountData.enabledFeatureFlags;
            this.redactionPending = accountData.redactionPending;
            this.accountingSystemReferenceName = accountData.accountingSystemReferenceName;

            if (accountData.salesSource.toUpperCase() === SalesSource.AMAZON) {
                this.isAmazonSummaryReportEnabled =
                    this.featureFlags?.includes(FEATURE_FLAGS.AMAZON_SUMMARY_REPORT) ?? false;
            }
            for (const page of Object.values(OMNI_ANALYTICS_PAGES)) {
                if (this.router.currentRoute.path.includes(page.path)) {
                    this.isClarityPage = true;
                    break;
                }
            }
        },
        async switchAccountAsync(accountKey: string, instance: Vue) {
            if (authStore().user.currentAccount === accountKey) {
                return;
            }
            /**
             * If we have query parameters while doing the switch account we will do a normal request
             * This is being done because someone can piggyback with ?userId= param and we fetch userId from account-status API which
             * is not included in this switchAccount because its not currently possible. Therefor to be on the safe side
             * we will just reload the page and do the usual /switch URL
             */
            const queryParams = this.router.currentRoute.query;
            if (queryParams && Object.keys(queryParams).length !== 0) {
                window.location.href = `/switch?account=${accountKey}`;
                return;
            }

            authStore().updateAccountListDataCurrentAccount(accountKey);

            loaderStore().setAccountDataSwitching();
            const response = await switchApi.switchAccountAsync({
                destinationAccountKey: accountKey,
            });

            if (containsErrorResponse(response)) {
                loaderStore().setAccountDataSwitched();
                const errorMessage = getErrorResponse(response);
                toast(instance, "danger", errorMessage);
                if (this.router.currentRoute.path !== "/admin") {
                    this.router.push("/admin");
                }
            } else {
                if (response) {
                    await oauthApi.getUserStatusAsync();

                    await this.updateAccountStoreDataAsync(response);
                    await authStore().setAppNavigationAsync();

                    if (this.router.currentRoute.path !== response.redirectUrl) {
                        this.router.push(response.redirectUrl);
                    }
                    loaderStore().setAccountDataSwitched();
                } else {
                    window.location.href = "/admin";
                    return;
                }
            }
        },
        initTracking() {
            const userId = authStore().user.userId;
            const partnerCodeExists = this.partnerCode ? true : false;
            const accountKey = this.accountKey;

            const defaultParams = {
                account_has_partner_id: partnerCodeExists ? true : false,
                account_key: accountKey,
                // GA4 param used to identify the user, not queryable in reports
                user_id: `atx-${userId}`,
                // GA4 param that can be queried in reports to get the exact user
                user_app_id: `atx-${userId}`,
                ...(!isProductionEnv() && { debug_mode: true, traffic_type: "internal" }),
            };

            const accountCentricParams = {
                ...defaultParams,
                user_id: `atx-${accountKey}`,
                user_app_id: `atx-${accountKey}`,
            };
            const accountAndUserCentricParams = {
                ...defaultParams,
                user_id: `atx-${accountKey}-${userId}`,
                user_app_id: `atx-${accountKey}-${userId}`,
            };
            setOptions(
                trackingConfig(defaultParams, accountCentricParams, accountAndUserCentricParams)
            );
            bootstrap().then(() => {
                // all done!
            });
        },
        isFeatureActive(featureFlag: FEATURE_FLAGS): boolean {
            return !!this.featureFlags?.includes(featureFlag);
        },
        // TODO: maybe pass as an array of feature flags to generalize even more
        async activateFeatureAsync(
            vm: Vue,
            featureFlag: FEATURE_FLAGS,
            extraGaProperties: Record<string, unknown> = {}
        ): Promise<void> {
            //lowercase the feature flag to match the GA event
            const featureFlagName = featureFlag.toLowerCase();
            sendStandardEvent(`feature_${featureFlagName}_activate`, {
                feature: FEATURES.BETA_FEATURE,
                label: "Activate",
                ...extraGaProperties,
            });
            const featureFlagNameUserFriendly = capitalize(featureFlagName.replace(/_/g, " "));
            // Specific feature flag that doesn't follow the pattern, therefore its not possible to just replace underscores with spaces
            await featureFlagApi.postFeatureFlagsAsync(this.accountKey, [featureFlag]);
            toast(vm, "success", `You've enabled the ${featureFlagNameUserFriendly} Beta feature!`);
            this.featureFlags?.push(featureFlag);
        },
        async deactivateFeatureAsync(
            featureFlag: FEATURE_FLAGS,
            extraGaProperties: Record<string, unknown> = {}
        ): Promise<void> {
            //lowercase the feature flag to match the GA event
            const featureFlagName = featureFlag.toLowerCase();
            sendStandardEvent(`feature_${featureFlagName}_deactivate`, {
                feature: FEATURES.BETA_FEATURE,
                label: "Deactivate",
                ...extraGaProperties,
            });
            await featureFlagApi.deleteFeatureFlagsAsync(this.accountKey, [featureFlag]);
            this.featureFlags?.splice(this.featureFlags?.indexOf(featureFlag), 1);
        },
    },
});
