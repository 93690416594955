export interface ISettlementsResponse {
    cursor?: string;
    limit: number;
    data: ISettlement[];
    total: number;
    error?: string;
}

export interface ISettlementsResponseNew {
    cursor?: string;
    limit: number;
    data: ISettlementsNew[];
    total: number;
    error?: string;
}

export type ISettlement = {
    accountKey: string;
    created: string;
    updated: string;
    start: string;
    end: string;
    total?: number;
    channel: string;
    gateway: string | null;
    currency?: string;
    salesSource: string;
    reportId: string;
    postedToXero: string | null;
    cogsPosted: string | null;
    postingStatus: PostingStatus | null;
    cogsPostingStatus: PostingStatus | null;
    cogsInvoicesTotal: string | number | null;
    cogsCurrency: string | null;
    allProductCostsAvailable: boolean | null;
    sellerId: string | null;
    duplicateOf: string | null;
    inconsistent: string | boolean | null;
    flagsSet: string[];
    onHold: boolean | null;
    onHoldReason: string | null;
    onHoldDate: string | null;
    keyAsString: string;
    taxesMapped: boolean | null;
    accountsMapped: boolean | null;
    fromV1?: string | null; // TODO no @expose
};

export type ICustomerDataMap = Partial<{
    customerFirstName: string;
    customerLastName: string;
    billingAddress1: string;
    billingAddress2: string;
    billingCity: string;
    billingZip: string;
    billingCountry: string;
    billingProvince: string;
    billingProvinceCode: string;
    phone: string;
    email: string;
    companyName: string;
    isFullyPaid: string;
}>;

export type ISettlementsNew = {
    id: string;
    datastoreKey: string;
    accountKey: string;
    created: string;
    updated: string;
    startDate: string;
    endDate: string;
    total: number;
    currency: string;
    channel: string;
    gateway: string | null;
    salesChannel: string;
    postedToAccountingSystem: null | string;
    hasCogs: boolean;
    postedCogs: null | string;
    postingStatus: PostingStatus | null;
    cogsPostingStatus: PostingStatus | null;
    cogsInvoicesTotal: number | null;
    cogsInvoicesCurrency: null | string;
    allProductCostsAvailable: boolean | null;
    inconsistent: string | boolean | null;
    flags: null | string[];
    onHold: null | boolean;
    onHoldReason: string | null;
    // onHoldDate: string | null; no OnHoldDate
    taxesMapped: boolean | null;
    accountsMapped: boolean | null;
    duplicateOfDatastoreSettlementKey: null | string | boolean;
    createdFromTransactions: boolean | null;
    // isB2B: boolean | null;
    blobKey: string | null;
    salesChannelReportId: string;
    // only populated when the sales source is SHOPIFY
    customerDataMap?: ICustomerDataMap | null;
};

// Type for settlements with attached extra data that is needed for table functionalities
export type ISettlementTableItem = ISettlement & {
    hasCogs?: boolean;
    disabled?: boolean;
    _rowVariant?: string;
};

export type ISettlementsTableItemNew = ISettlementsNew & {
    disabled?: boolean;
    _rowVariant?: string;
};

export type BulkActionStatus = {
    message: string;
    state: string;
    keyAsString: string;
};

export type ISettlementsSearchCriteria = {
    paginatedSearchCriteriaClause: IPaginatedSearchCriteria[][];
};

export type IPaginatedSearchCriteria = {
    filterKey: string;
    operation: string;
    value: string | number | boolean | string[] | null;
};

export type PaginatedQueryCustomOrderStatement = {
    property: string;
    direction: "ASC" | "DESC";
    nullsSortOrder?: "NULLS_FIRST" | "NULLS_LAST";
};

export enum PostingStatus {
    POSTED = "POSTED",
    PARTIALLY_POSTED = "PARTIALLY_POSTED",
    NOT_POSTED = "NOT_POSTED",
}
export enum PaginatedQuerySearchOperations {
    EQUAL = "eq",
    NOT_EQUAL = "ne",
    IN = "in",
    NOT_IN = "ni",
    NULL = "nu",
    NOT_NULL = "nn",
    GREATER_THAN = "gt",
    GREATER_THAN_EQUAL = "ge",
    LESS_THAN = "lt",
    LESS_THAN_EQUAL = "le",
    JSON_PROPERTY_CAST_BOOLEAN_TRUE = "jpcbtrue",
    JSON_PROPERTY_CAST_BOOLEAN_NOT_TRUE = "jpcbntrue",
}

export interface SearchCriteriaClause {
    filterKey: string;
    operation: PaginatedQuerySearchOperations;
    value: null | string | number;
}
