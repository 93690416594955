<template>
    <b-form-group
        v-if="totalRows > 0"
        label="Per page"
        label-for="per-page-select"
        label-cols="auto"
        label-align-lg="right"
        label-size="sm"
    >
        <b-form-select
            id="per-page-select"
            v-model="localPerPage"
            :options="pageOptions"
            size="sm"
            class="size-60"
            @change="changeHandler"
        ></b-form-select>
        &nbsp;
        <small>{{ paginationText }}</small>
    </b-form-group>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        totalRows: {
            type: Number,
        },
        perPage: {
            type: Number,
            default: 10,
        },
        pageOptions: {
            type: Array,
            default: () => [5, 10, 15, 100],
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            // set per page if it is in the page options, otherwise set the first option
            localPerPage: this.pageOptions.includes(this.perPage)
                ? this.perPage
                : (this.pageOptions[0] as number),
        };
    },
    computed: {
        fromTo(): string {
            if (this.totalRows === null) return "";
            return `${this.localPerPage * this.currentPage - this.localPerPage + 1} to ${
                this.localPerPage * this.currentPage > this.totalRows
                    ? this.totalRows
                    : this.localPerPage * this.currentPage
            }`;
        },
        paginationText(): string {
            if (this.totalRows === null) return "";
            return `Showing ${this.fromTo} of ${this.totalRows} entries`;
        },
    },
    methods: {
        changeHandler() {
            this.$emit("updatePerPage", this.localPerPage);
        },
    },
});
</script>
