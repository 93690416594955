
<div>
    <b-row>
        <b-col>
            <b-table
                ref="atxTable"
                stacked="md"
                :per-page="tablePagination.perPage"
                :current-page="tablePagination.currentPage"
                v-bind="$attrs"
                :sort-by="sortBy"
                v-on="$listeners"
                @row-clicked="onRowClicked"
            >
                <template #table-busy>
                    <atx-table-loader
                        :fields="atxTableReference.computedFields"
                        :row-number="atxTableReference.computedItems.length"
                    />
                </template>
                <template
                    v-for="(_, bTableSlot) of $scopedSlots"
                    #[bTableSlot]="scope"
                >
                    <slot
                        :name="bTableSlot"
                        v-bind="scope"
                    ></slot>
                </template>
            </b-table>
        </b-col>
    </b-row>
    <b-row v-if="pagination">
        <b-col>
            <b-form-group
                label="Per page"
                label-for="per-page-select"
                label-cols="auto"
                label-align-lg="right"
                label-size="sm"
            >
                <b-form-select
                    id="per-page-select"
                    v-model="tablePagination.perPage"
                    :options="visiblePageOptions"
                    size="sm"
                    class="size-60"
                    @input="paginationInputHandler"
                ></b-form-select>
                &nbsp;
                <small v-if="isMounted">
                    Showing {{ paginatedFromItems }} to
                    {{ paginatedToItems }}
                    of
                    {{ atxTableReference.filteredItems.length }} entries
                </small>
            </b-form-group>
        </b-col>
        <b-col>
            <b-pagination
                v-model="tablePagination.currentPage"
                pills
                :total-rows="tablePagination.totalRows"
                :per-page="
                    tablePagination.perPage === 0
                        ? tablePagination.totalRows
                        : tablePagination.perPage
                "
                align="right"
                size="sm"
                class="mb-3 atx-pagination"
                @input="paginationInputHandler"
            ></b-pagination>
        </b-col>
    </b-row>
    <b-row v-if="saveButton">
        <b-col>
            <atx-button
                id="btn-save-mappings"
                :disabled="saveButtonDisabled"
                variant="primary"
                @click="saveButtonClickHandler"
            >
                {{ saveButtonText }}
            </atx-button>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-alert
                v-for="msg in errorMessages"
                :key="msg"
                variant="atx-red-1"
                class="mt-3"
                show
            >
                >{{ msg }}
            </b-alert>
        </b-col>
    </b-row>
</div>
