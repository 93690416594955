<template>
    <b-button-group
        class="position-static"
        :data-test-id="testId"
    >
        <b-dropdown
            ref="atx-dropdown-range"
            variant="light"
            class="atx-dropdown mx-1"
            toggle-class="border-radius-4 px-2 py-1"
            menu-class="border-radius-12"
            :disabled="dropdownDisabled"
            boundary="window"
            no-caret
            @show="openDropdownRange"
            @hide="closeDropdownRange"
        >
            <template #button-content>
                <span class="dropdown-toggle-button-text">{{ dropdownButtonText }}</span>
                <atx-filter-counter-badge
                    v-if="badgeCounter > 0"
                    :counter="badgeCounter"
                />
                <atx-icon :icon="dropdownRangeShouldOpen ? 'chevron-up' : 'chevron-down'" />
            </template>

            <div>
                <b-row class="m-1 flex-nowrap">
                    <b-col
                        md="6"
                        sm="10"
                    >
                        <label for="input-default">Minimum:</label>
                        <b-form-input
                            id="start-value"
                            v-model="from"
                            type="number"
                            class="size-170"
                            placeholder="0"
                            step="1"
                            @change="inputFromChange"
                        ></b-form-input>
                    </b-col>
                    <b-col
                        md="6"
                        sm="12"
                    >
                        <label for="range-2">Maximum:</label>
                        <b-form-input
                            id="end-value"
                            v-model="to"
                            type="number"
                            class="size-170"
                            placeholder="999+"
                            step="1"
                            @change="inputToChange"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <atx-button-link
                            class="ml-3"
                            @click="clear"
                        >
                            Clear
                        </atx-button-link>
                    </b-col>
                </b-row>
            </div>
        </b-dropdown>
    </b-button-group>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        /**
         * Used to prevent dropdown from opening when dragging
         * (cases where the dropdown is inside a draggable element like horizontal scrollbar)
         */
        isDragged: {
            type: Boolean,
            required: false,
            default: false,
        },
        dropdownButtonText: {
            type: String,
            required: false,
            default: "Button text",
        },
        dropdownDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        badgeCounter: {
            type: Number,
            required: false,
            default: 0,
        },
        value: { type: Object, default: null },
        testId: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            dropdownRangeShouldOpen: false,
        };
    },
    computed: {
        from: {
            get(): string | null {
                if (!this.value) return null;

                return this.value.from;
            },
            set(value: string) {
                const payload = this.value || {};
                this.$emit("input", { ...payload, from: value });
            },
        },
        to: {
            get(): string | null {
                if (!this.value) return null;

                return this.value.to;
            },
            set(value: string) {
                const payload = this.value || {};
                this.$emit("input", { ...payload, to: value });
            },
        },
    },
    methods: {
        closeDropdownRange(): void {
            this.dropdownRangeShouldOpen = false;
        },
        openDropdownRange(e: Event): void {
            if (this.isDragged) {
                e.preventDefault();
                return;
            }
            this.dropdownRangeShouldOpen = true;
            this.$emit("dropdown-shown");
        },
        clear(): void {
            // The $emit 'input' is by design so that the values are cleared properly
            this.$emit("input", null);
            this.$emit("clear");
        },
        inputFromChange(): void {
            this.$emit("input-from-change");
        },
        inputToChange(): void {
            this.$emit("input-to-change");
        },
    },
});
</script>

<style scoped lang="scss"></style>
