
<b-button
    :class="{
        'min-width-8': fixed,
    }"
    v-bind="$attrs"
    :disabled="isLoading || $attrs.disabled"
    :variant="variant === '' ? 'atx-blue-2' : variant"
    :size="size"
    v-on="$listeners"
>
    <template v-if="isLoading">
        <span class="d-block h-0 invisible"><slot></slot></span>
        <b-spinner
            small
            :variant="variant === '' ? 'atx-blue-2' : variant"
            label="Loading..."
        ></b-spinner>
    </template>
    <template v-else>
        <template v-if="$scopedSlots.default">
            <span
                v-if="icon !== '' && iconLeft && !iconRight"
                class="d-inline-block"
            >
                <atx-icon
                    icon-left
                    :icon="icon"
                    space-right
                    :class="iconClass"
                />
            </span>
            <span :class="`d-inline-block ${textClass}`"><slot></slot></span>
            <span
                v-if="icon !== '' && iconRight"
                class="d-inline-block"
            >
                <atx-icon
                    :icon="icon"
                    space-left
                    :class="iconClass"
                />
            </span>
        </template>
        <template v-else>Default Text</template>
    </template>
</b-button>
