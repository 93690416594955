<template>
    <div class="h-100 position-relative">
        <apexchart
            :height="height"
            :options="chartOptions"
            :series="series"
        ></apexchart>
        <h4 class="h5 position-absolute text-center w-100 mb-0 bottom-0">{{ title }}</h4>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { AccountStatsBarChartSeries } from "@/models";
import apexchart from "vue-apexcharts";

export default Vue.extend({
    components: {
        apexchart,
    },
    props: {
        height: {
            type: String,
            default: "100%",
        },
        series: {
            type: Array,
            default: () => [] as AccountStatsBarChartSeries,
            required: true,
        },
        categories: {
            type: Array,
            default: () => [] as string[],
            required: true,
        },
        colors: {
            type: Array,
            default() {
                return ["#FFEFC1"];
            },
        },
        title: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: "solid",
                    colors: this.colors,
                },
                xaxis: {
                    categories: this.categories,
                    position: "top",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "solid",
                            color: "#DBDBDF",
                            opacity: 0.2,
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                },
                legend: {
                    show: false,
                },
            },
        };
    },
});
</script>
<style scoped>
.bottom-0 {
    bottom: 0;
}
</style>
