<template>
    <b-container
        fluid="xl"
        class="my-4"
    >
        <stepper-card-skeleton />
        <atx-separator :margin="4" />
        <div class="statistics-and-settlements-wrapper position-relative">
            <statistics-card-skeleton />
            <atx-separator :margin="4" />
            <settlements-card-skeleton />
        </div>
    </b-container>
</template>

<script lang="ts">
import Vue from "vue";
import StepperCardSkeleton from "@/pages/admin/__components__/skeletons/StepperCard.vue";
import SettlementsCardSkeleton from "@/pages/admin/__components__/skeletons/SettlementsCard.vue";
import StatisticsCardSkeleton from "@/pages/admin/__components__/skeletons/StatisticsCard.vue";

export default Vue.extend({
    components: {
        StepperCardSkeleton,
        SettlementsCardSkeleton,
        StatisticsCardSkeleton,
    },
});
</script>

<style scoped></style>
