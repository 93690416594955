<template>
    <b-table
        hover
        :items="data"
        :fields="summaryFields"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        outlined
        :small="small"
        head-variant="light"
    ></b-table>
</template>

<script lang="ts">
import Vue from "vue";
import { dateFormat } from "@/helpers/dateUtils";

export default Vue.extend({
    props: {
        data: {
            type: Array,
            default: () => Array,
            required: true,
        },
        summaryFields: {
            type: Array,
            default: () => Array,
            required: false,
        },
        displayTimeZone: {
            type: String,
            required: false,
        },
        sortBy: {
            type: String,
            required: false,
        },
        sortDesc: {
            type: Boolean,
            required: false,
        },
        small: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        dateWithTime(getTimestampInMs: string) {
            // value is expected to be a timestamp in milliseconds
            const dateObject = dateFormat(
                Number(getTimestampInMs),
                "yyyy-MM-dd HH:mm:ss",
                this.displayTimeZone
            );
            return dateObject;
        },
    },
});
</script>
