<template>
    <b-card no-body>
        <template #header>
            <b-row align-v="center">
                <b-col
                    sm="12"
                    md="5"
                    class="mb-3 mb-md-0"
                >
                    <b-skeleton
                        width="70%"
                        height="30px"
                    />
                </b-col>
                <b-col
                    sm="12"
                    md="7"
                    class="text-md-right"
                >
                    <b-skeleton
                        width="170px"
                        height="40px"
                        class="d-inline-block mr-4"
                    />
                    <b-skeleton
                        width="150px"
                        height="40px"
                        class="d-inline-block"
                    />
                </b-col>
            </b-row>
        </template>
        <b-card-body class="p-0">
            <table-skeleton />
        </b-card-body>
    </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import TableSkeleton from "./TableSkeleton.vue";
export default Vue.extend({
    components: {
        TableSkeleton,
    },
});
</script>

<style scoped></style>
