<template>
    <b-badge
        :class="{
            'atx-billing-badge': true,
            'bg-transparent': true,
            'text-atx-grey-4': !disabled,
            'text-atx-grey-2': disabled,
            border: true,
            'border-atx-grey-2': true,
        }"
    >
        <slot></slot>
    </b-badge>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
<style lang="scss">
.atx-billing-badge {
    padding: 7px;
}
</style>
