import { AccountingSystem } from "@/models";
import { SETTLEMENT_PAGE_ROUTES } from "@/router/routeConstants";

export function getResendModalMessage(
    accountingSystem: string,
    tContainer: string,
    invoiceTermPlural: string
): string {
    let modalMessage = `This ${tContainer} contains one or more ${invoiceTermPlural} that were already sent to ${accountingSystem}.`;

    if (accountingSystem.toUpperCase() === AccountingSystem.NETSUITE) {
        modalMessage += ` You can resend them, but please note that this will overwrite the previously sent journals.`;
    } else {
        modalMessage += ` You can resend them, but please remember to delete the duplicate ${invoiceTermPlural} in your accounting system.`;
    }

    return modalMessage;
}

export function getExportItemAndFileName(
    fileName: string,
    action: string
): { item: string; fileName: string } {
    let preparedFileName = fileName.replace(/\s+/g, "_");
    let item = "unknown_item";
    if (action === "exportcogs") {
        item = "COGS";
        preparedFileName += "_bulk-cogs-export";
    } else if (action === "export") {
        item = "settlement";
        preparedFileName += "_bulk-settlement-export";
    } else if (action === "send") {
        item = "settlement";
        preparedFileName += "_bulk-settlement-send";
    } else if (action === "sendcogs") {
        item = "COGS";
        preparedFileName += "_bulk-cogs-send";
    }

    return {
        item: item,
        fileName: preparedFileName,
    };
}

export function getSettlementLink(settlementKey: string): string {
    return `${SETTLEMENT_PAGE_ROUTES.SETTLEMENT.fullPath}?settlementKey=${settlementKey}`;
}

export function getVueSettlementLink(settlementKey: string): string {
    return `/app${SETTLEMENT_PAGE_ROUTES.SETTLEMENT.fullPath}?settlementKey=${settlementKey}`;
}

export function getExportSettlementLink(settlementKey: string): string {
    return `${SETTLEMENT_PAGE_ROUTES.EXPORT_SETTLEMENT.fullPath}?settlementKey=${settlementKey}`;
}

export function getExportCogsSettlementLink(settlementKey: string): string {
    return `${SETTLEMENT_PAGE_ROUTES.EXPORT_COGS.fullPath}?settlementKey=${settlementKey}`;
}

export function transactionContainer(salesChannel: string, plural?: boolean): string {
    if (salesChannel === null) return "";
    const getSalesChannel = salesChannel.toLocaleLowerCase();
    const transactionContainers = {
        amazon: "settlement",
        bigcommerce: "payout",
        shopify: "payout",
        etsy: "deposit",
        ebay: "payout",
        walmart: "settlement",
        paypal: "statement",
        afterpay: "settlement",
    } as Record<string, string>;

    return plural
        ? `${transactionContainers[getSalesChannel]}s`
        : transactionContainers[getSalesChannel];
}

export function getSettlementTerminology(
    salesChannel: string,
    lowercase = false,
    plural = false
): string {
    if (salesChannel === null || salesChannel === "") return "Settlements";
    const salesChannelLowerCase = salesChannel.toLowerCase();

    let terminology = {
        amazon: "Settlement",
        bigcommerce: "Payout",
        shopify: "Payout",
        etsy: "Deposit",
        ebay: "Payout",
        walmart: "Settlement",
        paypal: "Statement",
        afterpay: "Settlement",
    } as Record<string, string>;

    if (lowercase) {
        terminology = Object.keys(terminology).reduce(
            (acc, key) => {
                acc[key] = terminology[key].toLowerCase();
                return acc;
            },
            {} as Record<string, string>
        );
    }

    return plural ? `${terminology[salesChannelLowerCase]}s` : terminology[salesChannelLowerCase];
}

export function extractOrderNumberFromGateway(gateway: string): string {
    try {
        return gateway.split("#")[1].split("|")[0].trim();
    } catch (e) {
        return "";
    }
}

export function extractPoNumberFromGateway(gateway: string): string {
    try {
        return gateway.split("|")[1].replace("PO", "").replace("#", "").trim();
    } catch (e) {
        return "";
    }
}
