
<b-link
    :class="{
        'link-btn': true,
        'disabled text-atx-grey-3': disabled ? true : false,
        ...textColorClass,
    }"
    v-bind="$attrs"
    :variant="variant === '' ? 'atx-blue-2' : variant"
    v-on="$listeners"
>
    <template v-if="isLoading">
        <b-spinner
            small
            :variant="variant === '' ? 'atx-blue-2' : variant"
            label="Loading..."
        ></b-spinner>
    </template>
    <template v-else>
        <template v-if="$scopedSlots.default">
            <span
                v-if="icon !== ''"
                class="d-inline-block align-middle"
            >
                <atx-icon
                    v-if="icon !== ''"
                    :icon="icon"
                    space-right
                />
            </span>
            <span :class="`d-inline-block ${textClasses}`"><slot></slot></span>
        </template>
        <template v-else>Default Text</template>
    </template>
</b-link>
