
<b-container
    fluid="xl"
    class="my-4"
>
    <b-row>
        <b-col cols="3">
            <card-skeleton />
        </b-col>
        <b-col cols="9">
            <b-skeleton
                v-if="backButton"
                type="button"
                width="80px"
                height="24px"
            />
            <card-table-skeleton v-if="table" />
            <card-skeleton v-else />
        </b-col>
    </b-row>
</b-container>
