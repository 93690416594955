
<div>
    <b-skeleton
        width="200px"
        height="32px"
        class="mb-5"
    />
    <b-skeleton
        width="100%"
        height="16px"
        class="mb-3"
    />
    <b-skeleton
        width="100%"
        height="16px"
        class="mb-3"
    />
    <b-skeleton
        width="40%"
        height="16px"
    />
</div>
