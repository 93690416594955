import { defineStore } from "pinia";

export const useBackendStore = defineStore({
    id: "backend",
    state: () => ({
        fetchHistoryStatus: {
            filtersCount: {
                shopifyDataStateStatus: 0,
                shopifyDataStateType: 0,
                total: 0,
            } as Record<string, number>,
            filtersStale: false,
            currentFilters: {} as Record<string, Record<string, string[] | null> | null>,
        },
    }),
    actions: {
        updateFilters(filters: Record<string, string>) {
            if (
                Object.keys(filters).length === 0 ||
                Object.values(filters).every((item) => item === null)
            ) {
                this.clearFilters();
                return;
            }

            let total = 0;
            for (const key in filters) {
                if (filters[key] && typeof filters[key] === "object") {
                    // Don't count empty values
                    const valueCount = Object.values(filters[key]).filter(
                        (item) => item !== "" && item !== null
                    ).length;
                    if (key !== "date") {
                        this.fetchHistoryStatus.filtersCount[key] = valueCount;
                    } else {
                        this.fetchHistoryStatus.filtersCount[key] = valueCount > 1 ? 1 : valueCount;
                    }
                    total += this.fetchHistoryStatus.filtersCount[key];
                } else if (Array.isArray(filters[key])) {
                    this.fetchHistoryStatus.filtersCount[key] = filters[key].length;
                    total += this.fetchHistoryStatus.filtersCount[key];
                } else {
                    // strings - we could simplify this more and have always array
                    this.fetchHistoryStatus.filtersCount[key] = filters[key] ? 1 : 0;
                    total += this.fetchHistoryStatus.filtersCount[key];
                }
            }
            this.fetchHistoryStatus.filtersCount.total = total;
            // Transform the data in a flexible format to be used as a cross-check of what needs to be queried
            this.transformFiltersData(filters);
            this.fetchHistoryStatus.filtersStale = true;
        },
        transformFiltersData(filters: Record<string, string>) {
            for (const key in filters) {
                this.transformSingleFilter(key, filters[key]);
            }
        },
        transformSingleFilter(filterName: string, filterValue: string) {
            // Let's not do additional processing and reuse our reliable counter to define nulls
            if (this.fetchHistoryStatus.filtersCount[filterName] === 0) {
                this.fetchHistoryStatus.currentFilters[filterName] = null;
            } else {
                this.fetchHistoryStatus.currentFilters[filterName] = {
                    value: Array.isArray(filterValue) ? filterValue : [filterValue],
                };
            }
        },
        clearCurrentFiltersObject() {
            this.clearPropertiesRecursively(this.fetchHistoryStatus.currentFilters);
            this.fetchHistoryStatus.currentFilters = {};
            this.fetchHistoryStatus.filtersStale = true;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        clearPropertiesRecursively(obj: Record<string, any>) {
            for (const key in obj) {
                if (obj[key] === null || obj[key] === undefined) {
                    continue; // Skip null or undefined values
                } else if (typeof obj[key] === "object") {
                    this.clearPropertiesRecursively(obj[key]);
                } else {
                    obj[key] = null; // Set leaf properties to null
                }
            }
        },
        clearFilters() {
            this.clearCurrentFiltersObject();
            Object.entries(this.fetchHistoryStatus.filtersCount).forEach(
                ([key]) => (this.fetchHistoryStatus.filtersCount[key] = 0)
            );
            this.fetchHistoryStatus.filtersCount.total = 0;
        },
        applyFilters() {
            this.fetchHistoryStatus.filtersStale = false;
        },
    },
});
