<template>
    <b-container
        fluid="xl"
        class="my-4"
    >
        <tabbed-content-skeleton />
    </b-container>
</template>

<script lang="ts">
import Vue from "vue";
import TabbedContentSkeleton from "../components/TabbedContentSkeleton.vue";
export default Vue.extend({
    components: {
        TabbedContentSkeleton,
    },
});
</script>
