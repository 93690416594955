<template>
    <div class="layout-default d-flex flex-column vh-100">
        <atx-sidebar-navigation-skeleton />
        <atx-header-skeleton />
        <main style="flex: 1">
            <atx-page-header
                v-if="pageHeader"
                heading="Generic"
                :breadcrumbs="breadcrumbsList"
                :loaded="false"
            />
            <full-width-sidebar-right-view-skeleton
                :back-button="backButton"
                :table="table"
            />
        </main>
        <atx-footer version="2"></atx-footer>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import AtxHeaderSkeleton from "@/components/layout/AtxHeader/skeletons/AtxHeaderSkeleton.vue";
import AtxSidebarNavigationSkeleton from "../../layout/__components__/skeletons/AtxSidebarNavigationSkeleton.vue";
import FullWidthSidebarRightViewSkeleton from "../views/FullWidthSidebarRightViewSkeleton.vue";
export default Vue.extend({
    components: {
        AtxHeaderSkeleton,
        AtxSidebarNavigationSkeleton,
        FullWidthSidebarRightViewSkeleton,
    },
    props: {
        backButton: {
            type: Boolean,
            default: false,
        },
        pageHeader: {
            type: Boolean,
            default: true,
        },
        table: {
            type: Boolean,
            default: false,
        },
        mediumBlock: {
            type: Boolean,
            default: false,
        },
        breadcrumbs: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        breadcrumbsList(): string[] {
            return this.breadcrumbs ? ["test"] : [];
        },
    },
});
</script>

<style scoped></style>
