
<div class="d-inline-flex flex-row">
    <span :class="`order-${labelPosition === 'right' ? 1 : 0}`">
        {{ label }} ({{ percentage.toFixed() }}% complete).
    </span>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :class="`${labelPosition === 'right' ? 'mr-2' : 'ml-2'}`"
    >
        <circle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="#dde6f0"
            :stroke-width="svgStroke"
        />
        <circle
            class="percent"
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="#0672D3"
            :stroke-width="svgStroke"
            pathLength="100"
            :style="{ 'stroke-dashoffset': svgPercentage }"
        />
    </svg>
</div>
