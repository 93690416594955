
<div class="layout-default d-flex flex-column vh-100">
    <atx-sidebar-navigation-skeleton />
    <atx-header-skeleton />
    <main style="flex: 1">
        <atx-page-header
            v-if="pageHeader"
            heading="Generic"
            :breadcrumbs="breadcrumbsList"
            :loaded="false"
        />
        <full-width-sidebar-right-view-skeleton
            :back-button="backButton"
            :table="table"
        />
    </main>
    <atx-footer version="2"></atx-footer>
</div>
