<template>
    <div>
        <b-sidebar
            id="a2x-sidebar-nav"
            ref="a2x-sidebar-nav"
            v-model="sidebarVisible"
            title="Sidebar"
            backdrop-variant="atx-blue-3"
            bg-variant="atx-blue-4"
            text-variant="white"
            shadow="lg"
            :backdrop="isSmallDesktop || isTablet || isMobile"
            :sidebar-class="{
                'a2x-sidebar-nav': true,
                opened: sidebarVisible,
                closed: !sidebarVisible,
            }"
            no-close-on-route-change
            no-header
            v-bind="$attrs"
            @mouseover.native="maximizeSidebar"
            @mouseleave.native="minimizeSidebar"
            v-on="$listeners"
        >
            <div class="py-4 px-3">
                <ul class="p-0 m-0 list-unstyled">
                    <li>
                        <b-form-checkbox
                            v-if="sidebarVisible"
                            v-model="sidebarStaticCheckbox"
                            class="float-right"
                            name="check-button"
                            switch
                        ></b-form-checkbox>
                    </li>
                </ul>
                <slot name="sidebar-header"></slot>
                <slot name="sidebar-content-before"></slot>
                <slot name="sidebar-content"></slot>
            </div>
            <slot name="sidebar-footer"></slot>
        </b-sidebar>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        /**
         * Prop for outside control of sidebar visibility
         */
        sidebarStatic: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sidebarVisible: false,
            sidebarStaticCheckbox: !this.sidebarStatic, // prop for inside control of sidebar visibility
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            isMounted: false,
        };
    },
    computed: {
        /**
         * TODO: Maybe have separate store that has this data globally available?
         */
        isMobile(): boolean {
            return this.windowWidth <= 768;
        },
        isTablet(): boolean {
            return this.windowWidth > 768 && this.windowWidth <= 992;
        },
        isSmallDesktop(): boolean {
            return this.windowWidth > 992 && this.windowWidth <= 1400;
        },
        isMediumDesktop(): boolean {
            return this.windowWidth > 1400 && this.windowWidth <= 1900;
        },
        isLargeDesktop(): boolean {
            return this.windowWidth > 1900;
        },
    },
    watch: {
        sidebarStatic(newVal) {
            if (newVal) {
                this.sidebarVisible = true;
            } else {
                this.sidebarVisible = false;
            }
        },
    },
    created() {
        this.sidebarStaticCheckbox = this.sidebarStatic;
    },
    methods: {
        minimizeSidebar() {
            if (
                (!this.isMediumDesktop && !this.isLargeDesktop) ||
                this.sidebarStatic ||
                this.sidebarStaticCheckbox
            ) {
                return;
            }
            this.sidebarVisible = false;
        },
        maximizeSidebar() {
            if (
                (!this.isMediumDesktop && !this.isLargeDesktop) ||
                this.sidebarStatic ||
                this.sidebarStaticCheckbox
            ) {
                return;
            }
            if (!this.sidebarVisible) {
                this.sidebarVisible = true;
            }
        },
    },
});
</script>

<style lang="scss"></style>
