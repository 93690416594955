
<div class="position-relative donut-chart-container">
    <div
        v-if="icon"
        class="position-absolute value-icon"
    >
        <!-- TODO: The '$' in v-else is a temporary workaround. Remove once bootstrap icons is updated. -->
        <atx-icon
            v-if="icon !== '$'"
            :icon="icon"
            style="height: 2.5em; width: 2.5em"
        />
        <span
            v-else
            style="width: 2.5em; font-size: 2.5em; font-weight: normal"
        >
            $
        </span>
    </div>
    <div
        v-if="displayValue !== null"
        class="position-absolute bg-white donut-chart-value d-flex align-items-center justify-content-center"
        :class="{
            'border-radius-50': displayValue <= 9,
            'border-radius-pill': displayValue > 9,
        }"
    >
        <b-link
            v-if="displayValue > 0"
            class="display-value text-atx-blue-2 text-decoration-underline text-decoration-color-blue-2"
            @click="handleValueClick()"
        >
            {{ displayValue }}
        </b-link>
        <span
            v-else-if="errorState"
            class="text-atx-grey-3"
        >
            -
        </span>
        <b-icon
            v-else-if="emptyStateIcon"
            variant="atx-green-2"
            :icon="emptyStateIcon"
        />
        <span
            v-else
            class="text-atx-grey-3"
        >
            0
        </span>
    </div>
    <apexchart
        type="donut"
        :options="chartOptions"
        :series="series"
    ></apexchart>
</div>
