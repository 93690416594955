<template>
    <div :class="wrapperClasses">
        <b-row class="p-2">
            <b-col v-if="enablePredefinedRangePicker">
                <div class="d-flex flex-column border-right h-100 pr-3">
                    <atx-button
                        size="sm"
                        :variant="
                            monthSelected === 'thisMonth' ? 'atx-blue-2' : 'outline-atx-grey-2'
                        "
                        :class="buttonClasses(monthSelected === 'thisMonth')"
                        @click="selectMonth('thisMonth')"
                    >
                        This month
                    </atx-button>
                    <atx-button
                        size="sm"
                        :variant="
                            monthSelected === 'lastMonth' ? 'atx-blue-2' : 'outline-atx-grey-2'
                        "
                        :class="buttonClasses(monthSelected === 'lastMonth')"
                        @click="selectMonth('lastMonth')"
                    >
                        Last month
                    </atx-button>
                    <atx-button
                        size="sm"
                        :variant="yearSelected === 'thisYear' ? 'atx-blue-2' : 'outline-atx-grey-2'"
                        :class="buttonClasses(yearSelected === 'thisYear')"
                        @click="selectYear('thisYear')"
                    >
                        This year
                    </atx-button>
                    <atx-button
                        size="sm"
                        :variant="yearSelected === 'lastYear' ? 'atx-blue-2' : 'outline-atx-grey-2'"
                        :class="buttonClasses(yearSelected === 'lastYear')"
                        @click="selectYear('lastYear')"
                    >
                        Last year
                    </atx-button>
                </div>
            </b-col>
            <b-col>
                <b-row class="p-2">
                    <b-col md="6">
                        <label for="input-date-from">Start date</label>
                        <b-form-input
                            id="input-date-from"
                            ref="input-date-from"
                            v-model="inputDateFrom"
                            :disabled="disabled"
                            readonly
                            @click="showCalendar"
                            @blur="hideCalendar"
                        ></b-form-input>
                    </b-col>
                    <b-col md="6">
                        <label for="input-date-to">End date</label>
                        <b-form-input
                            id="input-date-to"
                            ref="input-date-to"
                            v-model="inputDateTo"
                            :disabled="disabled"
                            readonly
                            @click="showCalendar"
                            @blur="hideCalendar"
                        ></b-form-input>
                    </b-col>
                </b-row>

                <atx-date-picker
                    v-show="datepickerVisible"
                    ref="atx-range-date-picker"
                    v-bind="$attrs"
                    v-model="selected"
                    :calendar-class-main="calendarClassMain"
                    :calendar-class="{ 'border-0': true }"
                    :highlighted="dateRange"
                    :selected-from-date="dateRange.from"
                    :selected-to-date="dateRange.to"
                    :open-date="dateRange.from || new Date()"
                    v-on="$listeners"
                    @selected="setRange"
                ></atx-date-picker>
            </b-col>
        </b-row>
        <b-row class="px-2">
            <b-col>
                <div
                    v-if="showClearButton"
                    class="mt-auto"
                >
                    <b-link
                        class="date-range-clear-button"
                        @click="resetDateRange"
                    >
                        <strong>Clear</strong>
                    </b-link>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { AtxDatePicker } from "@/components/input";

export default Vue.extend({
    components: { AtxDatePicker },
    props: {
        /**
         * Must be unique calendar identifier for the page.
         **/
        calendarClassMain: {
            type: String,
            required: true,
            default: "",
        },
        alwaysVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        showClearButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        autoClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        enablePredefinedRangePicker: {
            type: Boolean,
            required: false,
            default: false,
        },
        storedDateRange: {
            type: Object,
        },
        initialDateRange: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dateRange: {
                from: null as null | Date,
                to: null as null | Date,
            },
            selected: null as null | Date,
            datepickerVisible: false,
            monthSelected: null as "thisMonth" | "lastMonth" | null,
            yearSelected: null as "thisYear" | "lastYear" | null,
        };
    },
    computed: {
        inputDateFrom: {
            get(): string {
                return this.dateRange.from ? this.formattedDate(this.dateRange.from) : "";
            },
            set(value: string) {
                this.dateRange.from = value ? new Date(value) : null;
            },
        },
        inputDateTo: {
            get(): string {
                return this.dateRange.to ? this.formattedDate(this.dateRange.to) : "";
            },
            set(value: string) {
                this.dateRange.to = value ? new Date(value) : null;
            },
        },
        wrapperClasses() {
            return {
                "atx-range-picker-wrapper m-2": true,
                extended: this.enablePredefinedRangePicker,
            };
        },
    },
    mounted() {
        if (this.alwaysVisible) {
            this.datepickerVisible = true;
        }
    },
    created() {
        if (this.initialDateRange) {
            this.dateRange.from = this.initialDateRange.from;
            this.dateRange.to = this.initialDateRange.to;
        }
        this.$watch(
            () => this.storedDateRange,
            (newVal) => {
                if (this.storedDateRange) {
                    this.selectCustomRange(newVal);
                    const dateRangeFrom = this.dateRange.from;
                    const dateRangeTo = this.dateRange.to;
                    const todaysDate = new Date();
                    const thisMonth = todaysDate.getMonth();
                    const thisYear = todaysDate.getFullYear();
                    const lastMonth = thisMonth === 0 ? 11 : thisMonth - 1;
                    const lastMonthYear = thisMonth === 0 ? thisYear - 1 : thisYear;

                    if (dateRangeFrom && dateRangeTo) {
                        const rangeFromMonth = dateRangeFrom.getMonth();
                        const rangeToMonth = dateRangeTo.getMonth();
                        const rangeFromYear = dateRangeFrom.getFullYear();
                        const rangeToYear = dateRangeTo.getFullYear();

                        if (rangeFromMonth === thisMonth && rangeToMonth === thisMonth) {
                            this.monthSelected = "thisMonth";
                        } else if (
                            rangeFromMonth === lastMonth &&
                            rangeToMonth === lastMonth &&
                            rangeFromYear === lastMonthYear &&
                            rangeToYear === lastMonthYear
                        ) {
                            this.monthSelected = "lastMonth";
                        } else if (rangeFromYear === thisYear && rangeToYear === thisYear) {
                            this.yearSelected = "thisYear";
                        } else if (rangeFromYear === thisYear - 1 && rangeToYear === thisYear - 1) {
                            this.yearSelected = "lastYear";
                        }
                    }
                }
            }
        );
    },

    methods: {
        selectRange(type: "month" | "year", value: "this" | "last") {
            const today = new Date();
            let startDate, endDate;

            if (type === "month") {
                const month = value === "this" ? today.getMonth() : today.getMonth() - 1;
                startDate = new Date(today.getFullYear(), month, 1);
                endDate = new Date(today.getFullYear(), month + 1, 0);
            } else {
                const year = value === "this" ? today.getFullYear() : today.getFullYear() - 1;
                startDate = new Date(year, 0, 1);
                endDate = new Date(year, 11, 31);
            }

            endDate.setHours(23, 59, 59, 999);

            this.dateRange.from = startDate;
            this.dateRange.to = endDate;
            this.monthSelected = type === "month" ? `${value}Month` : null;
            this.yearSelected = type === "year" ? `${value}Year` : null;

            this.$emit("range-selected", this.dateRange);
            this.autoCloseCalendar();
        },
        selectMonth(month: "thisMonth" | "lastMonth") {
            const eventMap = {
                thisMonth: "selectedThisMonth",
                lastMonth: "selectedLastMonth",
            };

            const value = month === "thisMonth" ? "this" : "last";
            this.$emit(eventMap[month]);
            this.selectRange("month", value);
        },
        selectYear(year: "thisYear" | "lastYear") {
            const eventMap = {
                thisYear: "selectedThisYear",
                lastYear: "selectedLastYear",
            };
            const value = year === "thisYear" ? "this" : "last";
            this.$emit(eventMap[year]);
            this.selectRange("year", value);
        },
        selectCustomRange(range: Record<"from" | "to", string>) {
            if (range.from === null || range.to === null) return;
            const rangeFrom = new Date(range.from);
            const rangeTo = new Date(range.to);

            rangeTo.setHours(23, 59, 59, 999);

            this.dateRange.from = rangeFrom;
            this.dateRange.to = rangeTo;

            this.$emit("range-selected", this.dateRange);
            this.autoCloseCalendar();
        },
        resetDateRange() {
            Object.assign(this.dateRange, { from: null, to: null });
            this.selected = null;
            this.monthSelected = null;
            this.yearSelected = null;
            this.$emit("clear", this.dateRange);
        },
        setRange(value: Date): void {
            const localDateRange = this.dateRange;
            if (!localDateRange.from) {
                localDateRange.from = value;
            } else {
                if (localDateRange.to) {
                    localDateRange.from = value;
                    localDateRange.to = null;
                } else if (value < localDateRange.from) {
                    localDateRange.from = value;
                    localDateRange.to = null;
                } else {
                    localDateRange.to = value;
                }
            }

            this.monthSelected = null;
            this.yearSelected = null;

            this.$emit("range-selected", this.dateRange);

            this.autoCloseCalendar();
        },
        formattedDate(date: Date): string {
            const options = { year: "numeric", month: "short", day: "2-digit" };
            return date.toLocaleDateString("en-US", options as Record<string, string>);
        },
        hideCalendar(event?: Event) {
            // Edge case: if blur event is triggered by the clear button (from inputs), reset the date range and possibly hide the calendar
            if (
                event &&
                (event as any).relatedTarget &&
                (event as any).relatedTarget.classList.contains("date-range-clear-button")
            ) {
                this.resetDateRange();
            }
            if (this.alwaysVisible) return false;
            this.datepickerVisible = false;
        },
        showCalendar() {
            this.datepickerVisible = true;
        },
        autoCloseCalendar() {
            if (this.autoClose && this.dateRange.to && this.dateRange.from) {
                this.hideCalendar();
            }
        },
        buttonClasses(isSelected: boolean) {
            return {
                "text-atx-blue-3 outline-atx-grey-2": !isSelected,
                "px-0 font-weight-normal rounded mb-2": true,
            };
        },
    },
});
</script>

<style lang="scss">
.atx-range-picker-wrapper {
    width: 320px;
    &.extended {
        width: 450px !important;
    }
    .form-control:disabled,
    .form-control[readonly] {
        background-color: transparent;
    }

    .vdp-datepicker__calendar {
        .cell {
            &.highlight-start {
                background-color: var(--atx-blue-2) !important;
                color: var(--white) !important;
                border-radius: 0 !important;
                border-top-left-radius: 50% !important;
                border-bottom-left-radius: 50% !important;
            }

            &.highlighted {
                background-color: var(--atx-blue-1);
            }

            &.highlight-end {
                background-color: var(--atx-blue-2) !important;
                color: var(--white) !important;
                border-radius: 0 !important;
                border-top-right-radius: 50% !important;
                border-bottom-right-radius: 50% !important;
            }

            &.highlight-start.highlight-end {
                border-top-left-radius: 50% !important;
                border-top-right-radius: 50% !important;
                border-bottom-left-radius: 50% !important;
                border-bottom-right-radius: 50% !important;
            }
        }
    }

    .clear-button {
        position: absolute;
        top: 7px;
        right: 10px;
    }
}
</style>
