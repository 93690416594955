import { defineStore } from "pinia";
import {
    IA2X2User,
    IAccountItem,
    IAccountListItems,
    IBillingAccountWrap,
    LoginEnablingParty,
    SalesSource,
} from "@/models";
import { IMenu, IMenuItem } from "@/components/models/IMenu";
import { storageService } from "@/services/storage.service";
import { useAccountStore } from "@/stores";
import { accountApi } from "@/api";
import { getMenu } from "@/data/real/menus";
import { useLoaderStore } from "./loader";
import { WHITELIST_PUBLIC_PAGES } from "@/globals";

type AuthState = {
    expired: boolean;
    user: User;
    partner: Partner | null;
    isLoggedIn: boolean;
    hasAdminPermissions: boolean | null;
    token: string;
    jwtRefreshToken: string;
    accessTokenExpiresAt: string;
    refreshTokenExpiresAt: string;
    showConnectionWarning: boolean;
    accountListData: IAccountListItems | Record<string, never>;
    appNavigation: IMenu | null;
    prodEnvironment: boolean;
    newDesign: boolean;
};

type User = IA2X2User & {
    billingAccountId: number | undefined | null;
    billingData: IBillingAccountWrap | null;
};

type Partner = {
    userId: string;
    billingAccountId: number | undefined | null;
    accounts: IAccountItem[];
    billingData: IBillingAccountWrap | null;
};

export type AuthenticateUserResponse = {
    authenticated: boolean;
    public: boolean;
};

export const useAuthStore = defineStore({
    id: "auth",
    state: () =>
        ({
            expired: false,
            user: {
                userId: "",
                email: "",
                currentAccount: "",
                partnerCode: "",
                accountsThatCanBeAccessedByThisUser: [],
                rootAdmin: true,
                supportLead: false,
                developer: false,
                supportAdmin: true,
                billingAccountId: null,
                billingData: null,
                loginEnablingParty: "GOOGLE" as LoginEnablingParty,
            },
            partner: null,
            isLoggedIn: false,
            hasAdminPermissions: null,
            token: "",
            jwtRefreshToken: "",
            accessTokenExpiresAt: "",
            refreshTokenExpiresAt: "",
            showConnectionWarning: true,
            accountListData: {}, //this hold's all the accounts from a user
            appNavigation: null,
            prodEnvironment: false,
            newDesign: false,
        }) as AuthState,
    getters: {
        isAuthenticated: (state) => {
            return state.isLoggedIn;
        },
        getToken: (state) => {
            return state.token;
        },
        getStorageAccountKey(): string | null {
            return storageService.getAccountKey();
        },
        getCurrentAccountKey(): string {
            return useAccountStore().accountKey ?? null;
        },
        getUserCurrentAccountObject(state): IAccountItem | null {
            // check for early loading
            if (state.accountListData.accounts === undefined) {
                return null;
            }
            return (
                state.accountListData.accounts.find((el) => el.isCurrent) ?? ({} as IAccountItem)
            );
        },
        getUserCurrentAccountKey(): string {
            return this.getUserCurrentAccountObject
                ? this.getUserCurrentAccountObject.accountKey
                : "";
        },
        internalCompanyEmail(): boolean {
            return this.user.email.includes("@a2xaccounting.com");
        },
        enablingPartyIcon(): string {
            switch (this.user.loginEnablingParty) {
                case "GOOGLE":
                    return "/images/google-small.png";
                case "XERO":
                    return "/images/xero-icon.png";
                case "QBO":
                    return "/images/qbo-icon.png";
                case "MICROSOFT":
                    return "/images/microsoft-icon.png";
                default:
                    // Set the default case to return the value for "GOOGLE"
                    return "/images/google-small.png";
            }
        },
        isDeveloper(): boolean {
            return this.user.developer;
        },
        /**
         * We want here the following:
         * 1. If the user is a root admin, has internal company email and is not in production environment
         * This means that we can still see the paypal in staging and we can use it for testing
         * @returns
         */
        isAccessibleToDeveloperUser(): boolean | null {
            return this.isDeveloper && this.internalCompanyEmail && !this.prodEnvironment;
        },
    },
    actions: {
        setCurrentAccountKey(accountKey: string) {
            this.user = { ...this.user, currentAccount: accountKey };
        },
        setStorageAccountKey(accountKey: string | null): void {
            if (!accountKey) return;
            storageService.setAccountKey(accountKey);
        },
        setAccountKeyStoreWide(accountKey: string): void {
            this.setCurrentAccountKey(accountKey);
            this.setStorageAccountKey(accountKey);
        },
        async setAppNavigationAsync(): Promise<void> {
            const userId = this.user.userId;
            const salesChannel = useAccountStore().salesChannel;
            const accountingSystem = useAccountStore().accountingSystem;
            const response = await accountApi.getListAsync(userId);
            if (response) {
                const currentMenu = getMenu(
                    accountingSystem,
                    salesChannel,
                    useAccountStore().featureFlags,
                    this.prodEnvironment
                );
                if (
                    salesChannel.toUpperCase() === SalesSource.AMAZON &&
                    !useAccountStore().isAmazonSummaryReportEnabled
                ) {
                    this.pruneAmazonSummaryReportTab(currentMenu);
                }

                this.accountListData = response;
                this.appNavigation = currentMenu;
            }
            useLoaderStore().setAccountListLoaded();
        },
        updateAccountListDataCurrentAccount(accountKey: string): void {
            const currentAccount = this.accountListData.accounts.find((item) => item.isCurrent);
            if (!currentAccount) return;

            currentAccount.isCurrent = false;
            const newCurrentAccount = this.accountListData.accounts.find(
                (item) => item.accountKey === accountKey
            );
            if (!newCurrentAccount) return;
            newCurrentAccount.isCurrent = true;
        },
        authenticateUser(): Promise<AuthenticateUserResponse> {
            let currentPath = window.location.pathname;
            currentPath = currentPath.replace(/\/app/g, "");
            const authenticateUserResponse: AuthenticateUserResponse = {
                authenticated: false,
                public: false,
            };
            Object.keys(WHITELIST_PUBLIC_PAGES).forEach((page) => {
                if (currentPath === page) {
                    authenticateUserResponse.public = true;
                }
            });

            // if we found public page no need to authenticate
            if (authenticateUserResponse.public) {
                return new Promise((resolve) => {
                    resolve(authenticateUserResponse);
                });
            }

            const accessToken = storageService.getAccessToken();
            const refreshToken = storageService.getRefreshToken();
            const accessTokenExpiresAt = storageService.getAccessTokenExpiresAt();
            const refreshTokenExpiresAt = storageService.getRefreshTokenExpiresAt();
            const userId = storageService.getUserId();
            const userEmail = storageService.getUserEmail();

            if (
                accessToken &&
                refreshToken &&
                accessTokenExpiresAt &&
                refreshTokenExpiresAt &&
                userId &&
                userEmail
            ) {
                this.setAuthTokens(
                    accessToken,
                    refreshToken,
                    accessTokenExpiresAt,
                    refreshTokenExpiresAt
                );
                this.$patch((state) => {
                    state.user.userId = userId;
                    state.user.email = userEmail;
                });

                return new Promise((resolve) => {
                    authenticateUserResponse.authenticated = true;
                    resolve(authenticateUserResponse);
                });
            }
            return new Promise((resolve) => {
                resolve(authenticateUserResponse);
            });
        },
        setAuthTokens(
            token: string,
            jwtRefreshToken: string,
            accessTokenExpiresAt: string,
            refreshTokenExpiresAt: string
        ): void {
            this.token = token;
            this.jwtRefreshToken = jwtRefreshToken;
            this.accessTokenExpiresAt = accessTokenExpiresAt;
            this.refreshTokenExpiresAt = refreshTokenExpiresAt;
        },
        pruneAmazonSummaryReportTab(currentMenu: IMenu) {
            if (currentMenu !== null) {
                currentMenu.mainMenu = currentMenu.mainMenu.map((menuItem: IMenuItem) => {
                    if (menuItem.name === "Reports") {
                        menuItem.subMenu = menuItem.subMenu?.filter(
                            (subMenuItem: IMenuItem) => subMenuItem.name !== "Summary Report"
                        );
                    }
                    return menuItem;
                });
            }
        },
    },
});
