<template>
    <div
        class="accordion my-4"
        role="tablist"
        :data-test-id="id"
    >
        <b-card
            no-body
            class="mb-1"
        >
            <b-card-header
                header-tag="header"
                class="p-0 bg-transparent"
                role="tab"
            >
                <template v-if="$slots.title">
                    <div
                        v-b-toggle="id"
                        class="d-flex justify-content-between"
                        :aria-expanded="accordionCollapse ? 'true' : 'false'"
                    >
                        <slot name="title"></slot>
                        <div class="d-flex justify-content-end p-3">
                            <atx-icon :icon="accordionCollapse ? 'chevron-up' : 'chevron-down'" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <atx-button
                        v-b-toggle="id"
                        class="text-left btn-outline-none p-3 d-flex justify-content-between"
                        :text-class="`font-weight-normal h5 mb-0 ${titleClass}`"
                        variant="transparent"
                        block
                        :icon="accordionCollapse ? 'chevron-up' : 'chevron-down'"
                        icon-class="text-atx-blue-2"
                        icon-right
                    >
                        {{ title }}
                    </atx-button>
                </template>
            </b-card-header>
            <b-collapse
                :id="id"
                v-model="accordionCollapse"
                :accordion="group"
                role="tabpanel"
            >
                <b-card-body body-class="p-3">
                    <slot></slot>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        titleClass: {
            type: String,
            default: "",
            required: false,
        },
        id: {
            type: String,
            default: "",
            required: true,
        },
        group: {
            type: String,
            default: "atx-accordion",
            required: false,
        },
        title: {
            type: String,
            default: "",
            required: false,
        },
    },
    data() {
        return {
            accordionCollapse: true,
        };
    },
});
</script>

<style scoped></style>
