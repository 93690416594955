
<b-progress
    :max="max"
    class="mb-3 border-radius-12 overflow-visible"
>
    <b-progress-bar
        class="position-relative border-radius-12 overflow-visible"
        :value="value"
        :class="`bg-${variant}`"
    >
        <div
            v-if="showValue"
            class="progress-bar-value d-flex align-items-center justify-content-center position-absolute bg-white"
        >
            <b-link
                v-if="value > 0"
                class="text-atx-blue-2 text-decoration-underline text-decoration-color-blue-2"
                @click="handleValueClick()"
            >
                {{ value }}
            </b-link>
            <span
                v-else-if="errorState"
                class="text-atx-grey-3"
            >
                -
            </span>
            <span
                v-else
                class="text-atx-grey-3"
            >
                0
            </span>
        </div>
    </b-progress-bar>
</b-progress>
