
<b-pagination
    pills
    :value="currentPageLocal"
    :total-rows="totalRows"
    :per-page="perPage"
    :align="align"
    size="sm"
    class="mb-3 atx-pagination"
    @change="changeCurrentPage"
/>
