
<b-form-group
    class="mb-0"
    :label="label"
    label-class="font-weight-bold"
    :label-for="id"
>
    <b-input-group size="sm">
        <b-form-input
            :id="id"
            :value="localValue"
            type="search"
            :placeholder="placeholder"
            @input="inputHandler"
        ></b-form-input>

        <b-input-group-append>
            <b-button
                :disabled="localValue == ''"
                @click="clearInput"
            >
                Clear
            </b-button>
        </b-input-group-append>
    </b-input-group>
</b-form-group>
