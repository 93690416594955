<template>
    <b-button
        size="sm"
        variant="outline-dark"
        @click="onCopyTextAsync(copyText)"
    >
        <b-icon icon="clipboard"></b-icon>
    </b-button>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        copyText: {
            required: true,
            type: String,
            default: "",
        },
    },
    methods: {
        async onCopyTextAsync(copyValue: string): Promise<void> {
            await navigator.clipboard.writeText(copyValue);
        },
    },
});
</script>

<style></style>
