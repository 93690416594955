<template>
    <footer class="bg-atx-blue-3 text-light border-top p-3 mt-auto">
        <b-container fluid="xl">
            <b-row>
                <b-col sm>
                    <strong>A2X</strong>
                    &copy; 2014 &ndash; {{ currentYear }} A2X Limited.
                    <small>
                        {{ printAppVersionLabel }}
                    </small>
                </b-col>
                <b-col
                    sm
                    class="text-right"
                >
                    <a
                        :href="SUPPORT_LINKS.SUPPORT.path"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-white"
                    >
                        Need help?
                    </a>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script lang="ts">
import { SUPPORT_LINKS } from "@/router/routeConstants";
import Vue from "vue";
export default Vue.extend({
    props: {
        version: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            SUPPORT_LINKS: SUPPORT_LINKS,
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        printAppVersionLabel(): string {
            return this.version !== null ? `v.${this.version}` : ``;
        },
    },
});
</script>

<style scoped></style>
