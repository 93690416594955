
<b-row>
    <b-col>
        <b-card
            class="w-100 card-tabs border-radius-12 border-0"
            no-body
        >
            <b-row no-gutters>
                <b-col
                    cols="12"
                    sm="4"
                    md="3"
                    lg="2"
                    class="tab-links-col"
                >
                    <b-button
                        id="settings-menu-toggle"
                        v-b-toggle="'settings-menu'"
                        class="m-1"
                        variant="link"
                        block
                    >
                        Options
                        <b-icon icon="caret-down-fill"></b-icon>
                    </b-button>
                    <b-collapse id="settings-menu">
                        <b-nav
                            v-for="i in 9"
                            :key="i"
                            card-header
                            pills
                            vertical
                            class="border-0 px-0 font-weight-semibold bg-atx-grey-1"
                        >
                            <b-nav-item
                                :key="i"
                                class="border-0"
                                link-classes="py-3 border-0"
                            >
                                <b-skeleton
                                    width="90%"
                                    height="1.5rem"
                                    class="mb-0"
                                />
                            </b-nav-item>
                        </b-nav>
                    </b-collapse>
                </b-col>
                <b-col
                    cols="12"
                    sm="8"
                    md="9"
                    lg="10"
                    class="tab-content-col"
                >
                    <b-card
                        header-tag="header"
                        header-bg-variant="white"
                        header-border-variant="gray"
                        no-body
                        class="card-content border-0"
                    >
                        <b-card-body>
                            <atx-heading
                                heading="Automation"
                                class="mb-5"
                            />
                            <content-block-skeleton />
                            <hr />
                            <content-block-skeleton />
                            <hr />
                            <content-block-skeleton />
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
    </b-col>
</b-row>
