
<b-row>
    <b-col>
        <atx-pagination-controls
            v-if="totalRows"
            :total-rows="totalRows"
            :current-page="currentPageLocal"
            :per-page="perPageLocal"
            :page-options="pageOptions"
            @updatePerPage="changePerPageHandler"
        />
    </b-col>
    <b-col>
        <atx-pagination-slim
            :total-rows="totalRows"
            :current-page="currentPageLocal"
            :per-page="perPageLocal"
            @change="changeCurrentPageHandler"
        />
    </b-col>
</b-row>
