<template>
    <div class="bg-atx-blue-3">
        <atx-navbar-main
            :menu="menu"
            :current-account="currentAccount"
            :user-email="userEmail"
            :enabling-party-icon="enablingPartyIcon"
            :user-id="userId"
            :show-advanced-fba="showAdvancedFba"
            :show-gift-cards="showGiftCards"
            :stripe-enabled="stripeEnabled"
            :whats-new-data="whatsNewData"
            @whats-new-dropdown-opened="handleWhatsNewDropdownOpen"
            @whats-new-view-more-clicked="handleWhatsNewViewMoreClicked"
            @whats-new-post-clicked="handleWhatsNewPostClicked"
        ></atx-navbar-main>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import AtxNavbarMain from "@/components/navigation/AtxNavbarMain";
import { IAccountItem } from "@/models";
import { IMenu } from "@/components/models/IMenu";
import { whatsNewApi, WhatsNewDataResponse } from "@/api/whats-new.api";
import { containsErrorResponse } from "@/helpers";
import { tracking } from "@/helpers/store";

export default Vue.extend({
    components: {
        AtxNavbarMain,
    },
    props: {
        menu: {
            type: Object as () => IMenu,
            default: null,
        },
        userEmail: {
            type: String,
            default: null,
        },
        enablingPartyIcon: {
            type: String,
            default: null,
        },
        userId: {
            type: String,
            default: null,
        },
        showAdvancedFba: {
            type: Boolean,
            default: false,
        },
        showGiftCards: {
            type: Boolean,
            default: false,
        },
        stripeEnabled: {
            type: Boolean,
            default: false,
        },
        currentAccount: {
            type: Object,
            default: {} as IAccountItem,
        },
    },
    data() {
        return {
            whatsNewData: [] as WhatsNewDataResponse[],
            tracking,
        };
    },
    async mounted() {
        const response = await whatsNewApi.getWhatsNewDataAsync();
        if (!containsErrorResponse(response)) {
            this.whatsNewData = response;
        }
    },
    methods: {
        handleWhatsNewDropdownOpen() {
            tracking.navigation.sendWhatsNewOpenDropdown();
        },
        handleWhatsNewViewMoreClicked() {
            tracking.navigation.sendWhatsNewViewMore();
        },
        handleWhatsNewPostClicked() {
            tracking.navigation.sendWhatsNewPostClicked();
        },
    },
});
</script>

<style>
.border-radius-0 {
    border-radius: 0;
}
</style>
