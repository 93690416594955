
<b-button-group
    class="position-static"
    :data-test-id="testId"
>
    <b-dropdown
        ref="atx-dropdown-input"
        variant="light"
        class="atx-dropdown mx-1"
        toggle-class="border-radius-4 px-2 py-1"
        menu-class="border-radius-12"
        :disabled="dropdownDisabled"
        boundary="window"
        no-caret
        @show="openDropdownRange"
        @hide="closeDropdownRange"
    >
        <template #button-content>
            <span class="dropdown-toggle-button-text">{{ dropdownButtonText }}</span>
            <atx-filter-counter-badge
                v-if="badgeCounter > 0"
                :counter="badgeCounter"
            />
            <atx-icon :icon="dropdownRangeShouldOpen ? 'chevron-up' : 'chevron-down'" />
        </template>

        <div>
            <b-row class="m-1 flex-nowrap">
                <b-col
                    md="12"
                    sm="12"
                >
                    <label
                        v-if="label"
                        for="input-default"
                    >
                        {{ label }}:
                    </label>
                    <b-form-input
                        id="start-value"
                        v-model="from"
                        type="number"
                        class="size-170"
                        :placeholder="placeholder"
                        step="1"
                        @change="inputFromChange"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <atx-button-link
                        class="ml-3"
                        @click="clear"
                    >
                        Clear
                    </atx-button-link>
                </b-col>
            </b-row>
        </div>
    </b-dropdown>
</b-button-group>
