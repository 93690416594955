
<b-card
    class="filter-scrollbar-actions mb-3 border-0"
    body-class="px-3 py-2"
>
    <b-row class="align-items-center">
        <b-col class="col-auto d-none d-md-flex">
            <atx-icon icon="filter" />
        </b-col>
        <b-col
            ref="filter-scrollbar-outer-wrap"
            :class="{
                'col-9 col-7 col-lg-8': true,
                'col-xl-9': scrollbarNeeded,
                'col-xl-auto p-0': !scrollbarNeeded,
            }"
        >
            <b-row class="align-items-center justify-content-around">
                <b-col class="col-auto p-0">
                    <atx-icon
                        v-if="scrollbarNeeded"
                        icon="arrow-left-short"
                        class="text-atx-blue-4 border-radius-100 align-middle filter-scrollbar-arrows"
                        style="
                            width: 24px;
                            height: 24px;
                            box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
                        "
                        @click="scrollX(-100)"
                    />
                </b-col>
                <b-col
                    :class="{
                        'col-auto col-10': true,
                        'col-lg-11': scrollbarNeeded,
                        'col-lg-auto': !scrollbarNeeded,
                    }"
                >
                    <div class="position-relative filter-scrollbar-wrap">
                        <div
                            v-if="scrollbarNeeded"
                            ref="filter-scrollbar-shadow-left"
                            class="filter-scrollbar-shadow-left"
                            style="opacity: 0"
                        ></div>
                        <perfect-scrollbar
                            ref="scrollbar"
                            class="position-static"
                            @ps-scroll-x="onScroll"
                        >
                            <div
                                ref="filter-scrollbar-inner-wrap"
                                class="d-flex flex-nowrap"
                            >
                                <slot name="content"></slot>
                            </div>
                        </perfect-scrollbar>
                        <div
                            v-if="scrollbarNeeded"
                            ref="filter-scrollbar-shadow-right"
                            class="filter-scrollbar-shadow-right"
                        ></div>
                    </div>
                </b-col>
                <b-col class="col-auto p-0">
                    <atx-icon
                        v-if="scrollbarNeeded"
                        icon="arrow-right-short"
                        class="text-atx-blue-4 border-radius-100 align-middle filter-scrollbar-arrows"
                        style="
                            width: 24px;
                            height: 24px;
                            box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
                        "
                        @click="scrollX(100)"
                    />
                </b-col>
            </b-row>
        </b-col>
        <b-col class="col-auto d-flex flex-nowrap align-items-center pr-0">
            <slot name="scrollbar-actions"></slot>
        </b-col>
    </b-row>
</b-card>
