
<div>
    <b-skeleton
        width="60%"
        height="32px"
        class="mt-6 mb-5"
    />
    <b-card
        no-body
        class="overflow-hidden mb-4"
    >
        <b-row class="align-items-center">
            <b-col
                md="4"
                class="bg-atx-blue-1"
            >
                <b-nav
                    vertical
                    class="pl-2 pr-3 py-4"
                >
                    <template v-for="n of 4">
                        <b-nav-item :key="n">
                            <div class="d-flex flex-grow-1 rounded-lg align-items-center">
                                <b-skeleton type="avatar" />
                                <div class="pl-3 w-100 h-100">
                                    <b-skeleton
                                        width="100%"
                                        height="64px"
                                        class="m-0"
                                    />
                                </div>
                            </div>
                        </b-nav-item>
                    </template>
                </b-nav>
            </b-col>
            <b-col md="8">
                <div class="p-4">
                    <b-card-body>
                        <b-skeleton
                            width="40%"
                            class="mb-5"
                        />
                        <b-skeleton width="90%" />
                        <b-skeleton width="80%" />
                        <b-skeleton width="40%" />
                        <b-skeleton
                            type="button"
                            class="mt-5"
                        />
                    </b-card-body>
                </div>
            </b-col>
        </b-row>
    </b-card>
</div>
