
<b-sidebar
    id="a2x-sidebar-nav"
    ref="a2x-sidebar-nav"
    v-model="sidebarVisible"
    backdrop-variant="atx-blue-3"
    title="Sidebar"
    bg-variant="atx-blue-4"
    text-variant="white"
    shadow="lg"
    no-header
    no-close-on-route-change
    :sidebar-class="{ opened: sidebarVisible, closed: !sidebarVisible }"
>
    <perfect-scrollbar>
        <div class="sidebar-shadow-bottom"></div>
        <div class="py-4 px-3">
            <b-nav
                vertical
                pills
                class="navbar-vertical"
            >
                <b-nav-item
                    v-for="n in 3"
                    :key="n + Math.random() + 'b'"
                    link-classes="text-white align-items-center d-flex border-radius-12 px-1"
                >
                    <b-skeleton
                        width="100%"
                        height="40px"
                        class="mb-0"
                    />
                </b-nav-item>
                <span
                    class="sidebar-separator border-top border-atx-grey-3-opaque-15 d-inline-block mt-2 mb-2 w-100"
                ></span>
                <b-nav-item
                    v-for="n in 19"
                    :key="n + Math.random() + 'a'"
                    class="text-white mb-1"
                    :link-classes="{
                        'text-white border-radius-12 d-flex align-items-center py-2 px-1': true,
                        'nav-item-min-width': sidebarVisible,
                    }"
                >
                    <b-skeleton
                        width="100%"
                        height="40px"
                    />
                </b-nav-item>
            </b-nav>
        </div>
    </perfect-scrollbar>
    <template #footer>
        <div class="d-flex bg-atx-blue-4 text-light align-items-center overflow-hidden p-3">
            <b-skeleton
                type="button"
                width="100%"
                :class="{
                    'text-left btn-create-new': true,
                    'nav-item-min-width': sidebarVisible,
                }"
            ></b-skeleton>
        </div>
    </template>
</b-sidebar>
