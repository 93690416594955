<template>
    <b-button
        class="btn-atx-brand-quickbooks"
        v-bind="$attrs"
        v-on="$listeners"
    ></b-button>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({});
</script>

<style lang="scss" scoped>
.btn-atx-brand-quickbooks {
    width: 223px;
    height: 36px;
    background-image: url("../../../assets/images/logo/qbo-C2QB-btn.svg");
    background-size: 100% 100%;
    background-color: transparent;
    border: none;
    border-radius: 0;
}

.btn-atx-brand-quickbooks:hover {
    background-image: url("../../../assets/images/logo/qbo-C2QB-btn-hover.svg");
}
</style>
