<template>
    <div class="position-relative donut-chart-container">
        <div
            v-if="icon"
            class="position-absolute value-icon"
        >
            <!-- TODO: The '$' in v-else is a temporary workaround. Remove once bootstrap icons is updated. -->
            <atx-icon
                v-if="icon !== '$'"
                :icon="icon"
                style="height: 2.5em; width: 2.5em"
            />
            <span
                v-else
                style="width: 2.5em; font-size: 2.5em; font-weight: normal"
            >
                $
            </span>
        </div>
        <div
            v-if="displayValue !== null"
            class="position-absolute bg-white donut-chart-value d-flex align-items-center justify-content-center"
            :class="{
                'border-radius-50': displayValue <= 9,
                'border-radius-pill': displayValue > 9,
            }"
        >
            <b-link
                v-if="displayValue > 0"
                class="display-value text-atx-blue-2 text-decoration-underline text-decoration-color-blue-2"
                @click="handleValueClick()"
            >
                {{ displayValue }}
            </b-link>
            <span
                v-else-if="errorState"
                class="text-atx-grey-3"
            >
                -
            </span>
            <b-icon
                v-else-if="emptyStateIcon"
                variant="atx-green-2"
                :icon="emptyStateIcon"
            />
            <span
                v-else
                class="text-atx-grey-3"
            >
                0
            </span>
        </div>
        <apexchart
            type="donut"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

export default Vue.extend({
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        series: {
            type: Array,
            default: () => [] as number[],
            required: true,
        },
        colors: {
            type: Array,
            default() {
                return ["#DBDBDF", "#0672D3"];
            },
        },
        labels: {
            type: Array,
        },
        icon: {
            type: String,
        },
        emptyStateIcon: {
            type: String,
            required: false,
        },
        displayValue: {
            type: Number,
            default: null,
        },
        errorState: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: "donut",
                },
                colors: this.colors,
                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "70%",
                            labels: {
                                show: false,
                            },
                        },
                    },
                },
                labels: this.labels,
                fill: {
                    type: "solid",
                    opacity: 1,
                },
                states: {
                    hover: {
                        filter: "none",
                    },
                },
                theme: {
                    palette: "palette2",
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
            },
        };
    },
    created() {
        if (this.series.length > 1 && this.series[0] === 0) {
            if (this.series[1] === 0) {
                // this is a workaround for when series is [0, 0] and the chart doesn't render.
                // this is a bug in apexcharts? or a feature. Either way, it doesn't render when
                // the second item in the series is 0.
                this.series[1] = 1;
            }
            this.chartOptions.colors = ["#8DC741", "#8DC741"];
        }
    },
    methods: {
        handleValueClick() {
            this.$emit("valueClicked");
        },
    },
});
</script>
