
<b-card
    :class="[
        'd-inline-block',
        'payment-card-details',
        'mb-4',
        'bg-atx-grey-1',
        'border-atx-grey-2',
        { 'with-coupon': couponToBeApplied },
    ]"
>
    <div>
        <img
            v-if="cardImagePath"
            class="bg-white"
            :src="cardImagePath"
        />
        <b-icon
            v-else
            class="h1"
            icon="credit-card2-front"
        />

        <span class="float-right h6">
            <b-link
                class="text-atx-blue-2"
                @click="(event) => changeBillingInformation(event)"
            >
                Update
                <b-icon
                    class="ml-1"
                    icon="pencil"
                />
            </b-link>
        </span>
    </div>
    <h4 class="font-weight-bold mb-1 d-flex align-items-center">
        <template v-for="n in 12">
            <b-icon
                :key="n"
                icon="asterisk"
                class="font-weight-bold mr-1 card-asterisk"
            />
            {{ n % 4 == 0 ? "&nbsp;&nbsp;" : "" }}
        </template>
        <span style="letter-spacing: 2px">
            {{ paymentMethod ? paymentMethod.lastFourDigits : "" }}
        </span>
    </h4>
    <small class="text-atx-grey-4 d-inline-block mb-3">Expires {{ cardExpireDate }}</small>
    <div class="d-flex justify-content-between">
        <div class="font-weight-bold">
            {{ cardHolderName }}
        </div>
        <atx-badge
            v-if="couponToBeApplied"
            :text="couponToBeApplied"
            icon="tag-fill"
            :extra-text="`${discount && discount.percentOff ? discount.percentOff + '%' : ''}`"
            variant="atx-green-1"
            extra-text-variant="atx-green-2"
        />
    </div>
</b-card>
