<template>
    <b-nav-item-dropdown
        class="whats-new-dropdown d-none d-md-block"
        no-caret
    >
        <template #button-content>
            <span
                class="label-new bg-gradient-orange position-relative"
                @click="dropdownHandler"
            >
                <atx-icon
                    style="width: 14px; height: 14px"
                    icon="gift-fill"
                    class="align-middle mr-1"
                />

                <span class="d-inline-block align-middle">NEW</span>

                <span
                    v-if="hasNewItems && showNotificationIndicator"
                    class="notification-wrapper position-absolute"
                    style="top: -5px; right: 11px"
                >
                    <span class="position-relative">
                        <b-icon
                            icon="circle-fill"
                            variant="atx-red-2"
                            class="animated-background-dot background-icon"
                            width="18"
                            height="18"
                        ></b-icon>
                        <b-icon
                            icon="circle-fill"
                            variant="atx-red-2"
                            class="animated-dot foreground-icon"
                            width="12"
                            height="12"
                        ></b-icon>
                    </span>
                </span>
            </span>
        </template>
        <b-dropdown-text variant="atx-blue-4">
            <span class="font-weight-bold">Latest updates</span>
        </b-dropdown-text>
        <!-- Loop over the first 5 items -->
        <b-dropdown-item
            v-for="(item, index) in limitedWhatsNewData"
            :key="index"
            class="p-0"
            :href="item.permalink"
            target="_blank"
            link-class="d-flex align-items-center justify-content-between"
            @click="$emit('post-clicked')"
        >
            {{ item.title }}
            <atx-badge
                v-if="isNew(item)"
                text="NEW"
                class="bg-gradient-orange ml-1 text-atx-blue-3"
            />
        </b-dropdown-item>
        <b-dropdown-item
            class="text-decoration-underline"
            variant="atx-blue-2"
            :href="WEBSITE_LINKS.WHATS_NEW.path"
            target="_blank"
            @click="$emit('view-more-clicked')"
        >
            View more
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script lang="ts">
import { WhatsNewDataResponse } from "@/api/whats-new.api";
import { storageService } from "@/services/storage.service";
import Vue from "vue";
import { WEBSITE_LINKS } from "@/router/routeConstants";

export default Vue.extend({
    props: {
        data: {
            type: Array as () => WhatsNewDataResponse[],
            default: [],
        },
    },
    data() {
        return {
            WEBSITE_LINKS: WEBSITE_LINKS,
            showNotificationIndicator: true,
            storageService: storageService,
        };
    },
    computed: {
        storedLatestDate(): Date {
            const storedLatestDateStr = this.storageService.getWhatsNewLatestDate();
            return storedLatestDateStr ? new Date(storedLatestDateStr) : new Date(0);
        },
        limitedWhatsNewData(): WhatsNewDataResponse[] {
            // Only show the first 5 items
            return this.data.slice(0, 5);
        },
        hasNewItems(): boolean {
            if (this.data.length > 0) {
                const newLatestDate = new Date(this.data[0].date);
                return newLatestDate > this.storedLatestDate;
            }
            return false;
        },
    },
    methods: {
        isNew(item: WhatsNewDataResponse): boolean {
            return new Date(item.date) > this.storedLatestDate;
        },
        dropdownHandler() {
            this.$emit("dropdown-opened");
            this.showNotificationIndicator = false;
            if (this.data.length > 0) {
                storageService.setWhatsNewLatestDate(this.data[0].date);
            }
        },
    },
});
</script>

<style lang="scss">
.whats-new-dropdown {
    .animated-dot {
        transform-origin: center;
        animation: 5s 1 atx-pulse ease-in-out forwards;
    }

    .animated-background-dot {
        transform-origin: center;
        animation: 5s 1 atx-pulse-big ease-in-out forwards;
    }

    .background-icon {
        position: absolute;
        top: 0;
        left: 0;
    }

    .foreground-icon {
        position: absolute;
        top: 3px;
        right: -16px;
    }

    @-moz-document url-prefix() {
        .notification-wrapper {
            transform: translate(0px, 14px); /* Adjust to fix the 14px issue */
        }
    }

    .dropdown-menu {
        width: 300px;
        white-space: normal;

        .dropdown-item {
            white-space: normal;
            word-wrap: break-word;
            word-break: break-word;
            &:hover,
            &:focus {
                background-color: $atx-blue-1;
                color: $atx-blue-3;
            }
        }
    }
}
</style>
