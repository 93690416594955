<template>
    <b-button-group class="position-static">
        <b-dropdown
            ref="atx-dropdown-datepicker"
            variant="light"
            class="atx-dropdown mx-1"
            toggle-class="border-radius-4 px-2 py-1"
            menu-class="border-radius-12"
            boundary="window"
            :disabled="dropdownDisabled"
            no-caret
            @show="openFilterSelect"
            @hide="closeFilterSelect"
        >
            <template #button-content>
                <span class="dropdown-toggle-button-text">
                    {{ formatDateSelected() }}
                </span>
                <atx-filter-counter-badge
                    v-if="badgeCounter > 0"
                    :counter="badgeCounter"
                />
                <atx-icon icon="chevron-down" />
            </template>

            <atx-date-picker
                :calendar-class-main="calendarClassMain"
                class="mx-3 my-2"
                v-bind="$attrs"
                :calendar-class="{ 'border-0': true }"
                :clear-button="false"
                v-on="$listeners"
                @input="dateSelectedHandler"
            ></atx-date-picker>
        </b-dropdown>
    </b-button-group>
</template>

<script lang="ts">
import Vue from "vue";
import AtxDatePicker from "../AtxDatePicker/AtxDatePicker.vue";
import { formatShortDate } from "@/helpers/dateUtils";

export default Vue.extend({
    components: {
        AtxDatePicker,
    },
    props: {
        /**
         * Must be unique calendar identifier for the page.
         **/
        calendarClassMain: {
            type: String,
            required: true,
            default: "",
        },
        isDragged: {
            type: Boolean,
            required: false,
            default: false,
        },
        dropdownButtonText: {
            type: String,
            required: false,
            default: "Button text",
        },
        dropdownDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        badgeCounter: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            activeDatePicker: "day",
            filterSelectShouldOpen: false,
            dateSelected: null as null | string,
            monthRange: {
                from: "",
                to: "",
            },
        };
    },
    watch: {
        dateSelected(newVal) {
            // Nullify our selected value as filters in tables need to receive null values and not empty array or empty object to correctly process internal filtering.
            if (newVal === null) {
                this.dateSelected = null;
            } else {
                this.dateSelected = newVal;
            }
        },
    },
    methods: {
        closeFilterSelect(): void {
            this.filterSelectShouldOpen = false;
        },
        openFilterSelect(e: Event): void {
            if (this.isDragged) {
                e.preventDefault();
                return;
            }
            this.filterSelectShouldOpen = true;
        },
        clear(): void {
            this.dateSelected = null;
        },
        dateSelectedHandler(value: string): void {
            if (value === null) {
                this.clear();
            } else {
                this.activeDatePicker = "day";
                this.dateSelected = value;
            }
        },
        getFirstDayOfMonth(date: string): Date {
            const dateObj = new Date(date);
            return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
        },
        getLastDayOfMonth(date: string): Date {
            const dateObj = new Date(date);
            return new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0);
        },
        formatDateSelected(): string {
            // only the month is sent we will show first to last day
            if (this.dateSelected === null) return this.dropdownButtonText;
            const dateSelected = new Date(this.dateSelected);
            // startDate format to Month date
            const formattedDate = formatShortDate(dateSelected);

            return `${this.dropdownButtonText}: ${formattedDate}`;
        },
    },
});
</script>

<style scoped></style>
