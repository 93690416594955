<template>
    <ul class="pagination">
        <li
            v-if="limitOptions"
            class="pagination-limit"
        >
            <span class="pagination-limit-label">Rows per page</span>
            <select
                v-model="theLimit"
                class="form-control pagination-limit-select"
            >
                <option
                    v-for="option in limitOptions"
                    :key="option"
                    :value="option"
                    :selected="option === theLimit"
                >
                    {{ option }}
                </option>
            </select>
        </li>
        <li class="pagination-previous">
            <a
                v-if="currentPage == 0"
                class="nav-button-disabled"
            >
                <b-icon
                    icon="chevron-left"
                    class="nav-button-disabled"
                    font-scale="2"
                ></b-icon>
            </a>
            <a
                v-if="currentPage != 0"
                class="nav-button"
                type="button"
                @click="previousPage"
            >
                <b-icon
                    icon="chevron-left"
                    class="nav-button"
                    font-scale="2"
                ></b-icon>
            </a>
        </li>
        <li class="pagination-page-number">{{ currentPage + 1 }} of many</li>
        <li class="pagination-next">
            <a
                v-if="isAtLastPage"
                class="nav-button-disabled"
            >
                <b-icon
                    icon="chevron-right"
                    class="nav-button-disabled"
                    font-scale="2"
                ></b-icon>
            </a>
            <a
                v-if="!isAtLastPage"
                class="nav-button"
                type="button"
                @click="nextPage"
            >
                <b-icon
                    icon="chevron-right"
                    class="nav-button"
                    font-scale="2"
                ></b-icon>
            </a>
        </li>
    </ul>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        currentPage: {
            type: Number,
            default: 0,
        },
        limit: {
            type: Number,
            default: 0,
        },
        limitOptions: {
            type: Array,
            default: null,
        },
        isAtLastPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theLimit: this.limit,
        };
    },
    watch: {
        // whenever question changes, this function will run
        theLimit(newLimit: number, oldLimit: number) {
            console.log("changed theLimit from " + oldLimit + " to " + newLimit);
            this.$emit("set-limit", newLimit);
        },
    },
    methods: {
        nextPage() {
            this.$emit("next-page");
        },
        previousPage() {
            this.$emit("previous-page");
        },
    },
});
</script>

<style scoped>
.nav-button {
    color: black;
    margin-top: 1.5px;
}
.nav-button-disabled {
    color: #dbdbdf;
    margin-top: 1.5px;
}
.nav-button:hover {
    color: #f7cf63;
}

.pagination {
    float: right;
    font-size: 0.7em;
    margin-top: 3em;
    margin-right: 1em;
}

.pagination-limit {
    width: 212px;
}

.pagination-limit-label {
    float: left;
    width: 100px;
    font-size: 14px;
    white-space: nowrap;
}

.pagination-limit-select {
    float: right;
    width: 80px;
    margin-right: 20px;
    margin-top: -7px;
    font-size: 14px;
}

.pagination-page-number {
    float: right;
    margin: 0 14px 3px 14px;
    font-size: 14px;
}
</style>
