
<v-select
    v-bind="$attrs"
    :calculate-position="calcPos"
    v-on="$listeners"
>
    <template
        v-for="(_, vSelectSlot) of $scopedSlots"
        #[vSelectSlot]="scope"
    >
        <slot
            :name="vSelectSlot"
            v-bind="scope"
        ></slot>
    </template>
</v-select>
