<template>
    <b-card
        class="filter-scrollbar-actions mb-3 border-0"
        body-class="px-3 py-2"
    >
        <b-row class="align-items-center">
            <b-col class="col-auto d-none d-md-flex">
                <atx-icon icon="filter" />
            </b-col>
            <b-col
                ref="filter-scrollbar-outer-wrap"
                :class="{
                    'col-9 col-7 col-lg-8': true,
                    'col-xl-9': scrollbarNeeded,
                    'col-xl-auto p-0': !scrollbarNeeded,
                }"
            >
                <b-row class="align-items-center justify-content-around">
                    <b-col class="col-auto p-0">
                        <atx-icon
                            v-if="scrollbarNeeded"
                            icon="arrow-left-short"
                            class="text-atx-blue-4 border-radius-100 align-middle filter-scrollbar-arrows"
                            style="
                                width: 24px;
                                height: 24px;
                                box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
                            "
                            @click="scrollX(-100)"
                        />
                    </b-col>
                    <b-col
                        :class="{
                            'col-auto col-10': true,
                            'col-lg-11': scrollbarNeeded,
                            'col-lg-auto': !scrollbarNeeded,
                        }"
                    >
                        <div class="position-relative filter-scrollbar-wrap">
                            <div
                                v-if="scrollbarNeeded"
                                ref="filter-scrollbar-shadow-left"
                                class="filter-scrollbar-shadow-left"
                                style="opacity: 0"
                            ></div>
                            <perfect-scrollbar
                                ref="scrollbar"
                                class="position-static"
                                @ps-scroll-x="onScroll"
                            >
                                <div
                                    ref="filter-scrollbar-inner-wrap"
                                    class="d-flex flex-nowrap"
                                >
                                    <slot name="content"></slot>
                                </div>
                            </perfect-scrollbar>
                            <div
                                v-if="scrollbarNeeded"
                                ref="filter-scrollbar-shadow-right"
                                class="filter-scrollbar-shadow-right"
                            ></div>
                        </div>
                    </b-col>
                    <b-col class="col-auto p-0">
                        <atx-icon
                            v-if="scrollbarNeeded"
                            icon="arrow-right-short"
                            class="text-atx-blue-4 border-radius-100 align-middle filter-scrollbar-arrows"
                            style="
                                width: 24px;
                                height: 24px;
                                box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
                            "
                            @click="scrollX(100)"
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="col-auto d-flex flex-nowrap align-items-center pr-0">
                <slot name="scrollbar-actions"></slot>
            </b-col>
        </b-row>
    </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default Vue.extend({
    components: {
        PerfectScrollbar,
    },
    props: {
        scrollbarData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isMounted: false,
            dragScrollParams: {
                startY: 0,
                startX: 0,
                scrollLeft: 0,
                scrollTop: 0,
                isDown: false,
                isDragged: false,
            },
            scrollbarNeeded: false,
            windowWidth: 0,
        };
    },
    computed: {
        filterScrollbarElement(): any {
            if (!this.isMounted) return {};
            return (this.$refs.scrollbar as any).ps;
        },
        scrollbarShadowLeftElement(): HTMLElement {
            if (!this.isMounted) return {} as HTMLElement;
            return this.$refs["filter-scrollbar-shadow-left"] as HTMLElement;
        },
        scrollbarShadowRightElement(): HTMLElement {
            if (!this.isMounted) return {} as HTMLElement;
            return this.$refs["filter-scrollbar-shadow-right"] as HTMLElement;
        },
        filterScrollbarInnerWrap(): HTMLElement {
            if (!this.isMounted) return {} as HTMLElement;
            return this.$refs["filter-scrollbar-inner-wrap"] as HTMLElement;
        },
        filterScrollbarOuterWrap(): HTMLElement {
            if (!this.isMounted) return {} as HTMLElement;
            return this.$refs["filter-scrollbar-outer-wrap"] as HTMLElement;
        },
    },
    watch: {
        windowWidth() {
            if (
                this.filterScrollbarInnerWrap.scrollWidth >
                this.filterScrollbarInnerWrap.clientWidth + 60
            ) {
                this.scrollbarNeeded = true;
            } else {
                this.scrollbarNeeded = false;
            }
        },
        scrollbarData: {
            handler(newVal) {
                if (newVal) this.maybeActivateScrollbar(newVal);
            },
            deep: true,
        },
    },
    mounted() {
        this.windowWidth = window.innerWidth;
        this.isMounted = true;
        this.filterScrollbarInitialSetup();
        this.$nextTick(() => {
            if (
                this.filterScrollbarInnerWrap.scrollWidth >
                this.filterScrollbarInnerWrap.clientWidth + 60
            ) {
                this.scrollbarNeeded = true;
            }
        });
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
        this.maybeActivateScrollbar(this.scrollbarData);
    },
    methods: {
        scrollX(x: number) {
            this.filterScrollbarElement.element.scrollBy({
                left: x,
                top: 0,
            });
        },
        maybeActivateScrollbar(
            data: Record<string, Record<string, string> | string | string[] | null>
        ) {
            this.$nextTick(() => {
                if (
                    this.filterScrollbarInnerWrap.scrollWidth >
                    this.filterScrollbarInnerWrap.clientWidth
                ) {
                    this.scrollbarNeeded = true;
                } else {
                    this.scrollbarNeeded = false;
                }

                if (Object.values(data).every((item) => item === null) && this.windowWidth > 1200) {
                    this.scrollbarNeeded = false;
                }
            });
        },
        onScroll() {
            if (!this.scrollbarNeeded) return;
            this.sidebarShadowsCalculate();
        },
        sidebarShadowsCalculate() {
            const currentScroll =
                this.filterScrollbarElement.lastScrollLeft /
                (this.filterScrollbarElement.contentWidth -
                    this.filterScrollbarElement.containerWidth);

            this.scrollbarShadowRightElement.style.opacity = (1 - currentScroll).toString();
            this.scrollbarShadowLeftElement.style.opacity = currentScroll.toString();
        },
        filterScrollbarDragScrollSetup() {
            if (this.filterScrollbarElement == undefined) return;

            this.filterScrollbarElement.element.addEventListener("mousedown", (e: Event) =>
                this.mouseIsDown(e)
            );
            this.filterScrollbarElement.element.addEventListener("mouseup", (e: Event) =>
                this.mouseUp(e)
            );
            this.filterScrollbarElement.element.addEventListener("mouseleave", (e: Event) =>
                this.mouseLeave(e)
            );
            this.filterScrollbarElement.element.addEventListener("mousemove", (e: Event) =>
                this.mouseMove(e)
            );
        },
        mouseLeave(e: Event) {
            this.dragScrollParams.isDown = false;
        },
        mouseUp(e: Event) {
            this.dragScrollParams.isDown = false;
            setTimeout(() => {
                this.dragScrollParams.isDragged = false;
                this.$emit("drag-scroll", false);
            }, 0);
        },
        mouseIsDown(e: Event) {
            if (this.filterScrollbarElement == undefined) return;
            this.dragScrollParams.isDown = true;
            this.dragScrollParams.startY =
                (e as any).pageY - this.filterScrollbarElement.element.offsetTop;
            this.dragScrollParams.startX =
                (e as any).pageX - this.filterScrollbarElement.element.offsetLeft;
            this.dragScrollParams.scrollLeft = this.filterScrollbarElement.element.scrollLeft;
            this.dragScrollParams.scrollTop = this.filterScrollbarElement.element.scrollTop;
        },
        mouseMove(e: Event) {
            if (this.filterScrollbarElement == undefined) return;
            if (this.dragScrollParams.isDown) {
                this.dragScrollParams.isDragged = true;
                this.$emit("drag-scroll", true);
                e.preventDefault();
                //Move vertcally
                const y = (e as any).pageY - this.filterScrollbarElement.element.offsetTop;
                const walkY = y - this.dragScrollParams.startY;
                this.filterScrollbarElement.element.scrollTop =
                    this.dragScrollParams.scrollTop - walkY;

                //Move Horizontally
                const x = (e as any).pageX - this.filterScrollbarElement.element.offsetLeft;
                const walkX = x - this.dragScrollParams.startX;
                this.filterScrollbarElement.element.scrollLeft =
                    this.dragScrollParams.scrollLeft - walkX;
            }
        },
        filterScrollbarInitialSetup() {
            // Unit tests break because settings does not exist
            if (this.filterScrollbarElement === undefined) return;
            this.filterScrollbarElement.settings.useBothWheelAxes = true;
            this.filterScrollbarElement.settings.suppressScrollY = true;
            this.filterScrollbarDragScrollSetup();
        },
    },
});
</script>
<style lang="scss">
.filter-scrollbar-actions {
    box-shadow: 0 0 0.45rem rgba(0, 0, 0, 0.15) !important;

    .filter-scrollbar-arrows {
        cursor: pointer;
    }
    .ps__rail-x,
    .ps__thumb-x {
        display: none;
    }
}

.filter-scrollbar-wrap {
    max-width: 852px;
    .ps__rail-x {
        left: 0 !important;
    }
}

.filter-scrollbar-shadow-left {
    opacity: 1;
    background: url("/src/assets/images/filter-shadow-left.png") center no-repeat;
    width: 20px;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    background-size: contain;
    pointer-events: none;
    z-index: 10;
}

.filter-scrollbar-shadow-right {
    opacity: 1;
    background: url("/src/assets/images/filter-shadow-right.png") center no-repeat;
    width: 20px;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    background-size: contain;
    pointer-events: none;
    z-index: 10;
}
</style>
