
<b-container
    v-if="heading !== '' || $slots.default"
    class="atx-page-header my-6"
>
    <template v-if="breadcrumbs && breadcrumbs.length > 0">
        <atx-breadcrumb
            v-if="loaded"
            :items="breadcrumbs"
        ></atx-breadcrumb>
        <b-skeleton
            v-else
            width="350px"
            height="24px"
            class="mb-6"
        />
    </template>
    <atx-heading
        :loaded="loaded"
        :heading="heading"
        :is-beta-feature="isBetaFeature"
        :description="description"
        :sales-channel="salesChannel"
        :route="route"
    >
        <template
            v-for="(_, headingSlot) of $slots"
            #[headingSlot]="scope"
        >
            <slot
                :name="headingSlot"
                v-bind="scope"
            ></slot>
        </template>
    </atx-heading>
</b-container>
