<template>
    <v-select
        v-bind="$attrs"
        :calculate-position="calcPos"
        v-on="$listeners"
    >
        <template
            v-for="(_, vSelectSlot) of $scopedSlots"
            #[vSelectSlot]="scope"
        >
            <slot
                :name="vSelectSlot"
                v-bind="scope"
            ></slot>
        </template>
    </v-select>
</template>

<script lang="ts">
import Vue from "vue";
import vSelect, { VueSelectInstance } from "vue-select";

export default Vue.extend({
    components: {
        vSelect,
    },
    methods: {
        calcPos(
            dropdownList: HTMLFormElement,
            component: VueSelectInstance,
            { top, left }: Record<string, string>
        ) {
            if (!("append-to-body" in this.$attrs)) {
                return;
            }
            // we use nextTick here because we need to wait for Vue to render this UL and then set the width to auto so we can take proper width
            this.$nextTick(() => {
                dropdownList.style.width = "auto";
                const listItems = dropdownList.getElementsByTagName("li");
                let maxWidth = 0;
                for (let index = 0; index < listItems.length; index++) {
                    const element = listItems[index];
                    if (maxWidth < element.offsetWidth) {
                        maxWidth = element.offsetWidth;
                    }
                }

                const leftPos = left;
                let leftPosNum = 0;

                if (leftPos.includes(".")) {
                    leftPosNum = parseFloat(leftPos);
                    leftPosNum = Math.floor(leftPosNum);
                } else {
                    leftPosNum = parseInt(leftPos);
                }

                //if the width of the list is going out of the clientWidth (we won't allow getting scroll-x)
                if (leftPosNum + maxWidth > window.innerWidth) {
                    maxWidth = window.innerWidth - leftPosNum - 30;
                }

                //in case we have list which width is lower then the found Max replace them.
                const componentInnerWidth = component.$refs.toggle.clientWidth;
                if (maxWidth < componentInnerWidth) {
                    maxWidth = componentInnerWidth + 1;
                }

                dropdownList.style.top = top;
                dropdownList.style.left = left;
                dropdownList.style.width = maxWidth + "px";
            });
        },
    },
});
</script>

<style scoped></style>
