<template>
    <b-form-group
        class="mb-0"
        :label="label"
        label-class="font-weight-bold"
        :label-for="id"
    >
        <b-input-group size="sm">
            <b-form-input
                :id="id"
                :value="localValue"
                type="search"
                :placeholder="placeholder"
                @input="inputHandler"
            ></b-form-input>

            <b-input-group-append>
                <b-button
                    :disabled="localValue == ''"
                    @click="clearInput"
                >
                    Clear
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </b-form-group>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        placeholder: {
            type: String,
            default: "Search by text",
            required: false,
        },
    },
    data() {
        return {
            localValue: this.value,
        };
    },
    methods: {
        inputHandler(eventValue: string) {
            this.localValue = eventValue;
            this.$emit("inputChanged", eventValue);
        },
        clearInput() {
            this.localValue = "";
            this.$emit("inputChanged", "");
        },
    },
});
</script>
