
<b-container
    fluid="xl"
    class="my-4"
>
    <b-skeleton
        v-if="backButton"
        type="button"
        width="80px"
        height="24px"
    />
    <card-table-skeleton v-if="table" />
    <b-row
        v-else-if="mediumBlock"
        class="justify-content-center"
    >
        <b-col cols="8">
            <card-skeleton />
        </b-col>
    </b-row>
    <card-skeleton v-else />
</b-container>
