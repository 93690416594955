
<b-container
    fluid="xl"
    class="my-4"
>
    <b-row>
        <b-col cols="8">
            <card-skeleton />
        </b-col>
        <b-col cols="4">
            <card-skeleton />
        </b-col>
    </b-row>
</b-container>
