
<b-modal
    v-bind="$attrs"
    :id="id"
    class="atx-modal"
    :title="title"
    :size="size"
    :centered="centered"
    hide-footer
    lazy
    static
    :no-close-on-esc="noCloseOnEscComputed"
    :no-close-on-backdrop="noCloseOnBackdropComputed"
    v-on="$listeners"
>
    <template #modal-header>
        <h4
            :class="{
                'my-0': true,
                'align-items-center d-flex justify-content-between':
                    $scopedSlots['modal-header-title-right-side'],
            }"
        >
            {{ title }}
            <slot name="modal-header-title-right-side"></slot>
        </h4>
        <button
            v-if="showCloseButton"
            size="sm"
            class="close"
            @click="cancel()"
        >
            ×
        </button>
    </template>
    <b-overlay
        :show="modalBusy"
        variant="white"
    >
        <div class="d-block">
            <div class="atx-modal-content">
                <slot></slot>
            </div>
            <div
                v-if="!hideActionButtons"
                class="d-flex mt-2"
            >
                <atx-button
                    class="mt-2 mr-2"
                    :variant="okVariant"
                    :disabled="okDisabled"
                    @click="ok"
                >
                    {{ okTitle }}
                </atx-button>
                <atx-button
                    v-if="!okOnly"
                    class="mt-2"
                    variant="outline-primary"
                    :disabled="cancelDisabled"
                    @click="cancel"
                >
                    {{ cancelTitle }}
                </atx-button>
            </div>
        </div>
    </b-overlay>
</b-modal>
