<template>
    <component
        :is="currentComponent"
        v-if="alertsExist"
        :class="{
            'atx-alerts': true,
            'my-6': container,
            'mb-6': !container,
        }"
    >
        <slot name="custom-alerts"></slot>
        <atx-alert
            v-for="alert in sortedAlerts"
            :key="alert.message"
            :top-space="false"
            :icon="alert.icon"
            :icon-variant="alert.iconVariant"
            :variant="alertVariant(alert.type)"
            :show="alert.show"
            :dismissible="alert.dismissible"
        >
            <span v-html="alert.message"></span>
        </atx-alert>
    </component>
</template>

<script lang="ts">
import { Alert } from "@/components/models/Alert";
import Vue from "vue";

/**
 * ## About
 * This component is used to display alerts on the page or in a specific places like tabbed content or even modal.
 * It has specific object structure that it expects to receive as a prop. This is to enforce as simple alerts as possible and generic across the app.
 * It can be wrapped with a container or a simple div depending on the use case.
 *
 * This should be used whenever you implement specific part of page that needs to display alerts in general. This way we keep the design consistency across the board.
 * For any specific alerts that are not generic, you can use `AtxAlert` component directly.
 *
 * ## Custom alerts
 * You can also pass custom alerts to this component. This is useful when you want to display alerts that require some custom work and binding of events like adding modal to a click and similar
 */
export default Vue.extend({
    props: {
        alerts: {
            type: Object as () => Alert[],
            default: () => ({}) as Alert[],
        },
        container: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentComponent: this.container ? "b-container" : "div",
        };
    },
    computed: {
        alertsExist(): boolean {
            if (Object.keys(this.alerts).length < 1) return false;

            let atLeastOneAlertToShow = false;
            for (const key in this.alerts) {
                if (this.alerts[key].show) {
                    atLeastOneAlertToShow = true;
                    break;
                }
            }

            return atLeastOneAlertToShow;
        },
        /**
         * Sort the alerts by type. We do danger, then warning and then info and then whatever else we have
         */
        sortedAlerts(): Alert[] {
            const alerts = Object.values(this.alerts);
            alerts.sort((a, b) => {
                if (a.type === "danger") return -1;
                if (b.type === "danger") return 1;
                if (a.type === "warning") return -1;
                if (b.type === "warning") return 1;
                if (a.type === "info") return -1;
                if (b.type === "info") return 1;
                return 0;
            });
            return alerts;
        },
    },
    methods: {
        alertVariant(type: string) {
            let alertVariant = "";
            switch (type) {
                case "danger":
                    alertVariant = "atx-red-1";
                    break;
                default:
                    alertVariant = type;
            }
            return alertVariant;
        },
    },
});
</script>

<style scoped></style>
