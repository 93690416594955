
<b-button
    class="atx-chevron"
    v-bind="$attrs"
    :disabled="isLoading"
    v-on="$listeners"
>
    <template v-if="isLoading">
        <b-spinner
            small
            label="Loading..."
        ></b-spinner>
    </template>
    <b-icon-chevron-right v-else />
</b-button>
