<template>
    <div class="bg-warning">
        <b-container>
            <div class="alert mb-0">
                <strong>Account Redaction Pending</strong>
                <span class="mx-2">This account will be deleted within 72 hours.</span>
                <a
                    target="_blank"
                    rel="noopener"
                    :href="SUPPORT_LINKS.SUPPORT_HOW_TO_DISCONNECT_SHOPIFY_STORE.path"
                >
                    Learn More
                </a>
                or
                <b-form
                    method="post"
                    action="/account_redaction"
                    class="d-inline"
                >
                    <b-form-input
                        class="d-none"
                        name="name"
                        value="value"
                    />
                    <atx-button
                        variant="primary"
                        size="sm"
                        type="submit"
                    >
                        Cancel Redaction
                    </atx-button>
                </b-form>
            </div>
        </b-container>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { SUPPORT_LINKS } from "@/router/routeConstants";

export default Vue.extend({
    data() {
        return {
            SUPPORT_LINKS: SUPPORT_LINKS,
        };
    },
});
</script>
